.lip-cheek {
  .bold {
    font-family: $medium-font-family;
  }
  .content-block-large {
    &__content {
      background-size: cover;
      @include breakpoint($medium-up) {
        min-height: 473px;
      }
    }
    &__content-text {
      flex-direction: column;
      &__content {
        width: 100%;
      }
    }
    &__content-text__header {
      width: 100%;
    }
    &--split-width {
      .content-block-large {
        &__content-buttons {
          margin-top: 33px;
          position: unset;
          @include breakpoint($medium-up) {
            text-align: left;
          }
        }
      }
    }
    &__text {
      text-align: left;
    }
  }
  .basic-carousel__slide {
    text-align: center;
    .image-text {
      position: relative;
      &__image {
        padding: 12px 0 80px;
      }
      &__subheader {
        position: absolute;
        top: 4px;
        width: 100%;
        height: 100%;
        @include breakpoint($medium-up) {
          top: 0;
        }
      }
      .formula.hed {
        font-family: $medium-font-family;
        font-size: 20px;
        line-height: 0.8;
        text-transform: uppercase;
        display: inline-block;
        background-color: $color-white;
        padding: 4px 6px 0;
        margin: 0;
        @include breakpoint($medium-up) {
          font-size: 30px;
          margin: 2px;
        }
      }
      .content-block-large__content-buttons {
        position: absolute;
        bottom: 12px;
        right: 0;
      }
    }
  }
  .basic-grid__item {
    .image-text {
      position: relative;
      &__image {
        padding: 12px 0 80px;
      }
      &__subheader {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .formula.hed {
        font-family: $medium-font-family;
        font-size: 30px;
        line-height: 0.8;
        text-transform: uppercase;
        display: inline-block;
        background-color: $color-white;
        padding: 4px 6px 0;
        margin: 2px;
      }
      .content-block-large__content-buttons {
        position: absolute;
        bottom: 12px;
        right: 0;
      }
    }
  }
  .limited.eyebrow {
    font-family: $medium-font-family;
    font-size: 11px;
    line-height: 1;
    color: $color-white;
    text-transform: uppercase;
    background-color: $color-rose;
    display: inline-block;
    padding: 5px 9px 3px;
    margin-bottom: 9px;
    @include breakpoint($medium-up) {
      font-size: 15px;
      padding: 6px 9px;
    }
  }
  h2 {
    font-family: $medium-font-family;
    text-transform: uppercase;
    line-height: 1;
    &.limited {
      font-size: 35px;
      color: $color-rose;
      @include breakpoint($medium-up) {
        font-size: 50px;
      }
    }
    &.ph-technology {
      font-size: 35px;
      color: $color-rose;
      @include breakpoint($medium-up) {
        font-size: 50px;
        color: $color-white;
      }
      p {
        padding: 45px 0 20px 95px;
      }
    }
    &.favorite {
      font-size: 30px;
      color: $color-rose;
    }
  }
  .ph-technology {
    p {
      @include breakpoint($medium-up) {
        padding: 45px 0 20px 95px;
        &:last-child {
          padding: 0 0 0 95px;
        }
      }
    }
  }
  .favorite.free {
    font-family: $medium-font-family;
    font-size: 35px;
    line-height: 1;
    color: $color-rose;
    text-transform: uppercase;
    border-left: 4px solid $color-rose;
    padding-left: 12px;
    margin: 80px 0 0;
    @include breakpoint($medium-up) {
      font-size: 22px;
    }
  }
  .basic-formatter__item {
    &:nth-child(3) {
      .content-block-large {
        @include breakpoint($small-down) {
          margin: 0;
        }
        &__content-text {
          &__content {
            width: 100%;
          }
        }
        &__content-text__header {
          width: 100%;
          @include breakpoint($small-down) {
            width: 90%;
            background-color: $color-white;
            padding: 12px;
            position: absolute;
            bottom: 42%;
          }
        }
        &--split-width {
          .content-block-large {
            &__content-text__subheader {
              @include breakpoint($medium-up) {
                padding: 45px 0 20px 95px;
              }
            }
            &__content-text__content {
              font-family: $medium-font-family;
              font-size: 18px;
              @include breakpoint($medium-up) {
                padding: 0 0 20px 95px;
              }
            }
          }
        }
      }
    }
    &:nth-child(4) {
      .content-block-large {
        @include breakpoint($small-down) {
          margin: 0;
        }
        &__content-text {
          &__content {
            width: 100%;
          }
        }
        &__content-text__header {
          width: 100%;
        }
        &--split-width {
          .content-block-large {
            &__content-text__subheader {
              padding: 45px 0 20px 95px;
            }
            &__content-text__content {
              font-size: 18px;
              @include breakpoint($medium-up) {
                padding: 0 0 20px 95px;
              }
            }
          }
        }
        &__content-buttons {
          @include breakpoint($medium-up) {
            text-align: left;
            padding-left: 95px;
          }
        }
      }
    }
    &:nth-child(5) {
      .content-block-large {
        @include breakpoint($small-down) {
          margin: 0;
        }
        &__content {
          @include breakpoint($medium-up) {
            width: 455px;
          }
        }
        &__content-text__header {
          margin: 24px 0 18px 0;
          @include breakpoint($medium-up) {
            margin: 0 0 18px 0;
          }
        }
        &__content-text {
          padding: 0;
          p {
            @include breakpoint($medium-up) {
              padding: 0 100px 0 0;
            }
          }
        }
        &__content-text__subheader p {
          font-size: 16px;
          line-height: 1.25;
          margin: 0 0 6px 0;
        }
        &__content-buttons:last-child {
          margin: 0;
        }
      }
    }
    &:nth-child(6) h2 {
      @include breakpoint($medium-up) {
        color: $color-rose;
        margin-bottom: 12px;
      }
    }
  }
  .button:active,
  .button:hover {
    color: $color-cyan-green;
  }
}
