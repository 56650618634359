#loyalty__panel__earn {
  @extend %loyalty-panel;
  h2 {
    width: 100%;
    border-bottom: solid 1px $color-light-gray;
    padding: 1em 0;
    text-align: center;
    @include breakpoint($landscape-up) {
      text-align: left;
    }
  }
  .loyalty__panel__earn {
    &__infobox {
      background-color: $color-light-gray;
      text-align: center;
      padding: 4em 1em;
      margin-bottom: 3em;
      h3 {
        font-weight: bold;
        padding-bottom: 0.6em;
      }
    }
    &__share {
      text-align: center;
      form {
        margin: 2em 0;
        input[type='text'] {
          width: 50%;
          @include breakpoint($landscape-up) {
            width: 60%;
          }
          font-size: 14px;
        }
        ::-webkit-input-placeholder {
          font-size: 14px;
        }
        ::-moz-placeholder {
          font-size: 14px;
        } /* firefox 19+ */
        :-ms-input-placeholder {
          font-size: 14px;
        } /* ie */
        input:-moz-placeholder {
          font-size: 14px;
        }
        button {
          height: 48px;
          width: 40%;
          @include breakpoint($landscape-up) {
            width: 20%;
          }
          vertical-align: bottom;
          margin-left: 1em;
          &.inverse_colors {
            border: solid 1px $color-black;
            background-color: $color-white;
            color: $color-black;
          }
        }
      }
      &__tabs {
        width: 100%;
        &__tab {
          display: inline-block;
          width: 33%;
          text-align: center;
          float: left;
          padding: 1em;
          outline: none;
          &.active {
            font-weight: bold;
            border-bottom: solid 2px;
          }
        }
      }
      &__email,
      &__facebook,
      &__twitter {
        background-color: #ccc;
        padding: 1em;
        margin-bottom: 3em;
        display: none;
        &.visible {
          display: block;
        }
        &__success {
          color: green;
        }
        &__error {
          color: red;
        }
        &__img {
          width: 160px;
          height: 160px;
          float: left;
          background-color: $color-dark-gray;
        }
        .icon {
          font-size: 20px;
        }
      }
    }
    &__referrals {
      border-bottom: solid 1px $color-light-gray;
      table {
        width: 100%;
        margin-bottom: 1em;
        td {
          width: 33%;
          padding: 1em 0;
        }
      }
    }
    &__footer {
      text-align: center;
      padding: 2em 0;
    }
  }
}
