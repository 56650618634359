///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
///
///
.site-header__section--utilities {
  .gnav-util-formatter__items {
    .gnav-util-formatter__item:nth-of-type(3) {
      width: 40%;
      padding-top: 2%;
      @include breakpoint($landscape-up) {
        width: auto;
        padding-top: 0;
      }
    }
  }
}

.gnav-util-formatter__item {
  display: inline-block;
  float: left;
  padding: 0 2% 0 2%;
  width: 100%;
  @include breakpoint($medium-up) {
    padding-top: 30px;
  }
}

.gnav-util__link {
  display: inline-block;
  padding: 0 1em;
}

.gnav-util__icon,
.utility-link {
  color: $color-black;
  line-height: $header-height;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  .icon:before {
    font-size: 23px;
    @include breakpoint($landscape-up) {
      font-size: 30px;
    }
  }
  &__cart-count {
    background: $color-pink;
    border-radius: 50%;
    color: $color-white;
    line-height: 1em;
    position: absolute;
    right: -0.95em;
    top: 50%;
    width: 20px;
    height: 20px;
    padding-top: 3px;
    text-align: center;
  }
}

.gnav-util__content {
  position: fixed;
  top: $header-height + 1px;
  right: 0;
  background: $color-white;
  border: 1px solid $color-black;
  width: 100%;
  z-index: 3;
  .site-header-formatter & {
    border: none;
  }
  &-close {
    background: transparent;
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 0.5em;
    text-decoration: none;
    width: 22px;
    height: 22px;
    @include breakpoint($landscape-up) {
      right: -20px;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &-inner {
    padding: 1em 40px 1em 1em;
  }
}

img.gnav-util__avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.gnav-util {
  &__cart {
    &-arrow {
      position: absolute;
      top: -12px;
      width: 25px;
      height: 12px;
      background: url(/media/images/global/util-dropdown-arrow.png) no-repeat 0 0;
      z-index: 10;
      right: 23px;
    }
    &-content {
      border: none;
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      top: 130px;
      &-close,
      &-edit {
        float: right;
        position: static;
        margin: 10px 0px;
        margin-right: 10px;
      }
    }
  }
}
