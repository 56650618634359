///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

.search-wrapper {
  &__results {
    &.results {
      @include breakpoint($landscape-up) {
        display: flex;
      }
      .product-grid__item {
        @include breakpoint($landscape-up) {
          min-height: 635px;
        }
      }
    }
  }
  &__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  .summary {
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
  }
  .results {
    &__products {
      .products {
        &__header {
          // temp hide sort
          display: none;
        }
      }
    }
    &__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
  }
  &__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
    }
  }
  .breadcrumb .breadcrumb__results,
  .dimension dd {
    padding-left: 0.5em;
    a {
      text-decoration: none;
    }
  }
}

// temporary 2 col layout for darphin only
// @setup new brand may remove these classes from markup
// or adjust accordingly
// darphin_base/template_api/endeca/esearch_results_pc_v1.mustache
.results__header--column {
  text-align: center;
  @include breakpoint($landscape-up) {
    width: 15%;
    height: 100%;
    position: relative;
    display: block;
    text-align: left;
  }
}

.results__products--column {
  margin: 0 1em;
  @include breakpoint($landscape-up) {
    width: 85%;
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
}

.esearch-product {
  text-align: center;
  width: 100%;
  a {
    text-decoration: none;
    img {
      @include breakpoint($landscape-up) {
        width: 295px;
      }
      &.product {
        &__image {
          &--hover {
            @include breakpoint($medium-down) {
              display: none;
            }
          }
        }
      }
    }
  }
  .product-brief {
    &__description-container {
      height: auto;
    }
  }
}

// plain styles for responsive result on search page
.endeca-test-image {
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 125px;
    background-color: $color-gray;
    &--small {
      width: 50px;
      height: 62px;
    }
  }
}

// Results filter
.results__filter {
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
  }
}

// dimensions
.results__header--dimensions {
  .results-header {
    cursor: pointer;
  }
}
