@mixin button {
  @include font-smoothing;
  @include appearance(none);
  font-family: $medium-font-family;
  display: inline-block;
  background: $color-cyan-green;
  color: $black;
  border: 1px solid $color-cyan-green;
  border-radius: 0;
  padding: 0 30px;
  min-width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: lighter;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: bottom;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  &:hover,
  &:active {
    text-decoration: none;
    background: $color-light-green;
    color: $color-white;
    border-color: $color-light-green;
  }
}

@mixin btn {
  @include font-smoothing;
  @include appearance(none);
  font-family: $medium-font-family;
  display: inline-block;
  background-color: $color-black-v2;
  border-color: $color-black-v2;
  color: $color-white;
  padding: 0 30px;
  height: 60px;
  min-width: 220px;
  line-height: 60px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: lighter;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: bottom;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  &:hover,
  &:active {
    text-decoration: none;
    background-color: $color-green-v2;
    border-color: $color-green-v2;
    color: $color-black-v2;
  }
}

%button {
  @include button;
}

@mixin button--secondary {
  @include button;
  background: $color-white;
  color: $color-dark-gray;
  border-color: $color-lighter-gray;
  &:hover {
    border-color: $color-pink;
  }
}

@mixin btn--secondary {
  @include btn;
  background: $color-white;
  color: $color-black-v2;
  border: 1px solid $color-black-v2;
  &:hover {
    color: $color-white;
    border-color: $color-green-v2;
  }
}

%button--secondary {
  @include button--secondary;
}

@mixin button--carousel {
  @include button;
  margin-top: 20px;
  height: 60px;
  line-height: 55px;
  font-size: 27px;
  @include breakpoint($landscape-up) {
    background: $color-lighter-gray;
    height: 40px;
    line-height: 38px;
    font-size: 12px;
  }
}

%button--carousel {
  @include button--carousel;
}

@mixin btn--inactive {
  background-color: $color-gray-v2;
  cursor: not-allowed;
  &:hover {
    background-color: $color-gray-v2;
    border-color: $color-gray-v2;
  }
}

@mixin btn--select {
  @include btn;
  background: $color-white;
  color: $color-black-v2;
  border: 1px solid $color-gray-v2;
  &:hover {
    background-color: $color-white;
    border-color: $color-gray-v2;
  }
  &:active {
    border: 1px solid $color-black-v2;
  }
}

.button {
  @extend %button;
  &--secondary {
    @extend %button--secondary;
  }
  &--disabled {
    background: $color-gray;
    color: $color-white;
    border-color: $color-gray;
    cursor: default;
    &:hover {
      background: $color-gray;
      color: $color-white;
      border-color: $color-gray;
    }
  }
  &--wide {
    width: 100%;
  }
  &--carousel {
    @extend %button--carousel;
  }
}

.btn {
  @include btn;
  &--secondary {
    @include btn--secondary;
    &-light {
      background: none;
      border: 1px solid $color-white;
      color: $color-white;
    }
    &-dark {
      background: none;
      border: 1px solid $color-black-v2;
      color: $color-black-v2;
    }
  }
  &--inactive {
    @include btn--inactive;
  }
  &--select {
    @include btn--select;
  }
}

input[type='submit'] {
  @include button;
  padding: 0 10px;
  min-width: auto;
}

///
/// Links
///

@mixin link {
  font-size: 14px;
  color: $color-dark-gray;
  border-bottom: 1px solid $color-dark-gray;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s, border-color 0.3s;
  cursor: pointer;
  letter-spacing: 0.085em;
  &:hover {
    color: $color-light-gray;
    border-color: $color-light-gray;
    text-decoration: none;
  }
}

%link {
  @include link;
}

@mixin link--secondary {
  @include link;
  font-size: 11px;
}

%link--secondary {
  @include link--secondary;
}

.link {
  @extend %link;
  &--inverted {
    @include font-smoothing;
    color: $color-white;
    border-color: $color-white;
  }
  &--secondary {
    @extend %link--secondary;
  }
}
