@import '../../core_base/scss/core_base_pc';

// common css
@import 'base/generic';

.site-footer {
  .country-chooser {
    img,
    picture {
      max-width: 10%;
    }
  }
  .site-email-signup {
    &__field {
      @include breakpoint($landscape-up) {
        width: 69%;
      }
    }
    input[type='submit'] {
      @include breakpoint($landscape-up) {
        width: 29%;
      }
    }
  }
}

.mpp-container {
  .product-brief {
    &__name {
      padding-top: 10px;
    }
    &__subline {
      @include breakpoint($landscape-up) {
        min-height: 96px;
      }
    }
  }
}

.store-locator {
  &__content {
    .city {
      @include breakpoint($medium-only) {
        width: 93%;
      }
    }
  }
}
