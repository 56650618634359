.cart-block {
  font-size: 12px;
  padding: 0px 10px;
  @include breakpoint($landscape-up) {
    width: 450px;
  }
  a {
    text-decoration: none;
    font-weight: bold;
  }
  &__header {
    min-height: 20px;
  }
  &__header-title {
    font-weight: bold;
    font-size: 14px;
  }
  &__content,
  &__header-title {
    margin: 10px 0px;
  }
  &__item-count {
    color: $color-dark-gray;
    font-size: 1.25em;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: block;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    &--view-bag {
      display: block;
      padding: 1em 0 2em;
    }
  }
  &__footer {
    margin: 15px 0px;
    &-summary {
      @include pie-clearfix;
      display: none;
      margin: 15px 0px;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__item-count {
      float: left;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      display: block;
      &-label {
        font-size: 14px;
        float: left;
      }
      &-value {
        font-size: 14px;
        float: right;
      }
    }
    &-button {
      width: 100%;
      &-checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
        }
      }
      &-continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
    .promos-wrapper {
      text-align: center;
      &__content {
        margin: 10px 0px;
      }
      &__link {
        text-decoration: underline;
      }
    }
  }
}
