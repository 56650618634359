#gift_card {
  padding: 15px 11px 15px 10px;
  @include breakpoint($landscape-up) {
    max-width: 980px;
    padding: 40px 45px 60px 0;
    margin: 0 auto;
    font-size: 12px;
  }
  a:hover,
  a:active {
    color: $color-light-green;
  }
  #content {
    color: $color-gray;
    h1 {
      color: $color-gray;
    }
    h2 {
      font-size: 30px;
      font-weight: bold;
      color: $color-gray;
      text-align: left;
    }
    h3 {
      color: $color-gray;
    }
    float: right;
    padding-bottom: 25px;
    @include breakpoint($landscape-up) {
      padding-bottom: 50px;
      width: 720px;
    }
  }
  #sidebar a,
  .gc_balance_head {
    text-transform: none;
  }
  // Gift cards Navigation
  .nav-gift-card {
    .sub_nav_egifts {
      border-bottom: 2px solid $color-darker-grey;
      margin-bottom: 10px;
      padding-bottom: 5px;
      .nav_buy_egift {
        border-right: 2px solid $color-darker-grey;
        padding: 0 4px 0 3px;
      }
      .nav_balance {
        border-right: 2px solid $color-darker-grey;
        padding: 0px 4px 0px 4px;
      }
    }
    border-bottom: 0px solid;
    border-top: 0px solid;
    margin-top: 0px;
    @include breakpoint($landscape-up) {
      border-top: none;
      margin-top: 0;
      padding: 0px;
    }
    &__title {
      text-transform: none;
      border-bottom: 1px solid $color-darker-grey;
      font-weight: 700;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
    &__content {
      li {
        padding: 0px 0px;
      }
    }
  }
  fieldset.giftcard {
    @include breakpoint($large-up) {
      .gift-card-content .gift-card-content__panel {
        margin-bottom: 0;
      }
      .gift-card-content .gift-card-content__description {
        margin-bottom: 0;
      }
    }
    .gift-card-content .gift-card-form__elements .form_element input {
      @include breakpoint($large-up) {
        height: 28px;
      }
    }
    .gift-card-content .gift-card-form__elements {
      @include breakpoint($large-up) {
        width: 120%;
      }
    }
    .gift-card-content .gift-card-form__elements .form_element {
      @include breakpoint($large-up) {
        width: 50%;
      }
    }
    .gift-card-content .form-balance input {
      @include breakpoint($large-up) {
        width: 100%;
      }
    }
    .email_address_containers label {
      text-transform: none;
    }
    label {
      letter-spacing: -0.05em;
    }
    div {
      margin-bottom: 10px;
      @include breakpoint($large-up) {
        margin-bottom: 20px;
      }
    }
    @include breakpoint($large-up) {
      min-width: 100%;
    }
    .value_container {
      label {
        text-transform: none;
      }
    }
    div.form_element {
      input {
        height: 28px;
        width: 100%;
      }
      select {
        color: $color-gray;
        height: 28px;
        width: 100%;
        display: block;
        font-size: 12px;
        line-height: 18px;
        font-family: $main-font;
      }
      textarea {
        width: 100%;
      }
    }
  }
  .sidebar-pc {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
    .sub_nav_egifts {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  .sidebar-mobile {
    .nav-gift-card__title {
      display: none;
    }
    .nav-gift-card__content {
      display: none;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
    .sub_nav_egifts {
      letter-spacing: -0.02em;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  #sidebar {
    margin-right: 0;
    .nav-gift-card ul a:hover,
    a:visited,
    a:link,
    a:active {
      text-decoration: none;
      font-weight: bold;
    }
    @include breakpoint($landscape-up) {
      float: right;
      width: 185px;
      margin-right: 25px;
    }
    .nav-gift-card__content {
      padding-top: 0px;
    }
  }
  .left_container {
    p {
      padding: 0;
      line-height: 1.45em;
      @include breakpoint($landscape-up) {
        font-size: 12px;
      }
    }
    .gift_img {
      text-align: left;
      padding-top: 15px;
      padding-bottom: 15px;
      @include breakpoint($landscape-up) {
        padding-top: 5px;
        padding-bottom: 10px;
      }
    }
    @include breakpoint($landscape-up) {
      float: left;
      width: 430px;
      padding-right: 25px;
    }
  }
  .right_container {
    .error_messages li {
      color: $color-error;
    }
    color: $color-gray;
    float: left;
    .req_files-pc {
      display: none;
    }
    @include breakpoint($landscape-up) {
      .req_files-pc {
        font-weight: bold;
        display: block;
      }
      .req_files {
        font-weight: bold;
      }
      margin-top: -42px;
      width: 280px;
      padding-left: 82px;
    }
  }

  //About Gift Cards
  #content .about_egiftcard .purchasing {
    border-bottom: 1px solid $color-darker-grey;
    margin-bottom: 5px;
  }
  #content .about_egiftcard .redeeming {
    border-bottom: 1px solid $color-darker-grey;
    margin-bottom: 5px;
  }
  #content .about_egiftcard h1 {
    text-transform: none;
    font-size: 19px;
    padding: 5px 0 7px;
    letter-spacing: 0;
  }
  #content .about_egiftcard h3 {
    padding-top: 5px;
  }
  #content .about_egiftcard #content_body {
    margin-bottom: 10px;
    line-height: 18px;
    ul li {
      list-style-type: disc;
      margin-left: 17px;
      padding: 3px 0;
    }
  }
  #content .about_egiftcard .terms_conditions .about_contact {
    font-weight: bold;
  }
  //Balance
  #content .right_container .check_side_bar {
    margin-top: 10px;
    display: none;
    @include breakpoint($landscape-up) {
      margin-top: 0;
      display: block;
    }
  }
  #content .right_container .check_side_bar h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  #content .right_container .check_side_bar ul li {
    list-style-type: decimal;
    padding: 5px 0;
    font-weight: bold;
    margin-left: 17px;
    text-transform: uppercase;
    line-height: 12px;
  }
  #content .right_container .check_side_bar ul li span {
    font-weight: normal;
    text-transform: none;
  }
  #content .left_container #form--errors--giftcard_balance li {
    color: $color-error;
  }
  #content .gift-card-content .form-balance .form_element {
    @include breakpoint($landscape-up) {
      position: relative;
      width: 210px;
    }
  }
  #content .gift-card-content .giftcard_number {
    @include breakpoint($landscape-up) {
      padding: 5px 40px 10px 0;
    }
  }
  #content .fieldset_balance {
    .form-balance input {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 195px;
      }
    }
    @include breakpoint($landscape-up) {
      width: 430px;
    }
  }
}
//Terms_Conditions
.egiftcard_terms_content {
  font-size: 12px;
  .about_contact {
    font-weight: bold;
    padding: 10px 0 0 4px;
  }
  ul li {
    list-style-type: disc;
    margin-left: 17px;
    padding: 3px 0;
  }
}

#gift_card_overlay_container .content_container .gift_img_sample {
  text-align: center;
}
