.account-requirement {
  margin-top: 23px;
  text-align: center;
  @include breakpoint($medium-up) {
    margin-top: 50px;
  }
  .title {
    font-size: 23px;
    font-weight: 400;
    padding-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 40px;
      padding-bottom: 17px;
    }
  }
  .disclaimer {
    display: block;
    font-size: 13px;
    margin-bottom: 14px;
  }
  .button {
    display: block;
    margin: 8px auto;
    max-width: 261px;
  }
  .sign-in-afterpay {
    display: block;
    font-size: 13px;
    margin-top: 20px;
    a {
      display: block;
      margin-top: 4px;
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-top: 0;
      }
    }
  }
}
.afterpay {
  &-faq {
    font-size: 25px;
    margin-top: 37px;
    text-align: center;
    @include breakpoint($medium-up) {
      font-size: 40px;
    }
    &-section {
      .customer-service {
        &__breadcrumb {
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
        &__content {
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
      }
    }
    .description {
      font-size: 19px;
      margin-top: 13px;
    }
    &-title {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 4px;
      margin-top: 33px;
      text-align: center;
      @include breakpoint($medium-up) {
        font-size: 40px;
        margin-bottom: 25px;
      }
    }
    &-content {
      margin-top: 10px;
      text-align: center;
      p {
        margin: 1px auto;
        width: 298px;
        @include breakpoint($medium-up) {
          margin: auto;
          width: 320px;
        }
      }
    }
    &-header {
      font-weight: 700;
      margin-top: 5px;
      text-align: center;
    }
  }
  &-service {
    text-align: center;
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 7%;
    }
  }
  &-works {
    margin-top: 15px;
    @include breakpoint($medium-up) {
      display: inline-block;
      margin-#{$rdirection}: 60px;
    }
    .image-wrapper {
      text-align: center;
    }
  }
  &-questions {
    margin-#{$ldirection}: 15px;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 37px;
    }
  }
  .customer-service__content {
    width: 100%;
  }
}
