.page-not-found {
  padding: 0 10px;
  text-align: center;
  &__wrapper {
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    @include breakpoint($landscape-up) {
      padding: 100px 0;
    }
  }
  &__header {
    font-size: 42px;
    line-height: 1;
    margin-bottom: 35px;
    font-weight: normal;
    @include breakpoint($landscape-up) {
      font-size: 50px;
    }
  }
  &__subheading {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 26px;
  }
  &__link {
    text-decoration: underline;
    text-decoration-color: $color-light-grey;
    font-weight: bold;
    font-size: 24px;
  }
  &__subcopy {
    font-size: 18px;
    margin: 30px 0px 10px 0px;
  }
  &__image {
    display: block;
    margin: 0 auto;
  }
  &__button {
    margin-top: 30px;
  }
}
