.content-block-large {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 0;
  margin-bottom: 0;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &.mpp-banner {
    display: block;
    margin-bottom: 0;
  }
  .kinesio_video & {
    margin-bottom: 0;
  }
  @include breakpoint($landscape-up) {
    margin-bottom: 0;
  }
  &.no-image {
    .content-block-large__content-leadin {
      @include breakpoint($landscape-up) {
        position: static;
      }
      .content-block-large__content {
        position: relative;
        height: auto;
      }
    }
  }
  .icon {
    color: inherit;
    font-size: 90px;
    cursor: pointer;
  }
  &__inner {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    &--border-top {
      border-top: 1px solid $color-lighter-gray;
    }
  }
  picture img {
    z-index: 1;
    width: 100%;
  }
  &__prod-picture picture {
    img {
      display: block;
      margin: 0 auto;
      width: 85%;
    }
    @include breakpoint($landscape-up) {
      width: auto;
      display: block;
      margin: 0 auto;
      img {
        .mpp-banner & {
          max-height: 220px;
        }
      }
    }
  }
  &__content {
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    align-items: center;
    flex-direction: column;
    display: flex;
    top: 0;
    left: 0;
    padding: 8%;
    .mpp-banner,
    .kinesio_video & {
      padding-bottom: 0px;
    }
    &--mobile-full-width {
      padding: 0;
      @include breakpoint($medium-up) {
        padding: 8%;
      }
    }
    @include breakpoint($landscape-up) {
      height: 100%;
      padding: 50px;
      min-height: 530px;
      margin-left: auto;
      margin-right: auto;
      .mpp-banner & {
        padding: 30px 0;
        min-height: 310px;
      }
      .content-block-large--split-width & {
        min-height: 0;
        padding: 0;
      }
    }
    &.image_full_width {
      background-size: cover;
    }
  }
  &.image_full_width {
    .content-block-large {
      &__prod-picture img {
        width: 100%;
      }
      &__content-text,
      &__content {
        padding: 0;
        background: transparent;
      }
    }
  }
  &__content-text {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-light-green-transparent;
    .no-bg-color & {
      background: none;
    }
    @include breakpoint($medium-up) {
      min-height: 470px;
    }
    @include breakpoint($landscape-up) {
      height: 100%;
      padding: 20px;
      min-height: auto;
      justify-content: center;
      flex-direction: row;
    }
    .content-block-large--split-width & {
      background: none;
    }
    .content-block-large--story & {
      background: none;
      padding-left: 30px;
      padding-right: 30px;
      @include breakpoint($landscape-up) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &-header {
      text-align: center;
    }
  }
  &__copy {
    order: 2;
    @include breakpoint($landscape-up) {
      max-width: 505px;
    }
  }
  &__tandcs {
    font-size: 12px;
    line-height: 14px;
    margin: 40px 20px 0 20px;
  }
  &__header {
    text-transform: uppercase;
  }
  &__prod-picture {
    order: 1;
    .mpp-banner & {
      @include breakpoint($landscape-up) {
        margin-right: 10%;
      }
    }
  }
  &__content-buttons {
    text-align: center;
    position: relative;
    width: 100%;
    left: 0;
    margin-bottom: 20px;
    &--include-in-mobile {
      position: relative;
      bottom: 0;
      margin-bottom: 30px;
    }
    @include breakpoint($landscape-up) {
      position: relative;
      width: auto;
      bottom: auto;
      margin-bottom: initial;
    }
    .button {
      width: 84%;
      height: auto;
      margin-top: 15px;
      @include breakpoint($landscape-up) {
        width: auto;
        margin-top: 20px;
      }
    }
  }
  &__content-leadin {
    margin-top: auto;
    text-align: center;
    width: 100%;
    color: $white;
    @include breakpoint($landscape-up) {
      position: absolute;
      bottom: 0;
      left: 0;
      a {
        color: inherit;
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
    }
    .icon {
      display: block;
      font-size: 32px;
    }
  }
  .horizontal-align-left {
    align-items: flex-start;
  }
  .horizontal-align-center {
    align-items: center;
  }
  .horizontal-align-right {
    align-items: flex-end;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  @include breakpoint($landscape-up) {
    .max-width-30 {
      max-width: 30%;
    }
    .max-width-40 {
      max-width: 40%;
    }
    .max-width-50 {
      max-width: 50%;
    }
    .max-width-60 {
      max-width: 60%;
    }
    .max-width-70 {
      max-width: 70%;
    }
    .max-width-80 {
      max-width: 80%;
    }
    .max-width-90 {
      max-width: 90%;
    }
    .max-width-100 {
      max-width: 100%;
    }
  }
  &__main-picture {
    margin: 0px;
  }
  &__secondary-sections {
    padding: 35px 0 0 0;
    @include breakpoint($landscape-up) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__secondary-section {
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 47%;
    }
  }
  &--story {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    position: relative;
    margin-bottom: 0;
    .content-block-large__content-text {
      padding-top: 50px;
      &--no-top {
        padding-top: 0;
      }
    }
  }
  &__secondary-picture {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      margin-bottom: 30px;
    }
  }
  &--landing-page {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    position: relative;
    h2 {
      font-size: 28px;
      color: $black;
      text-align: center;
      padding: 65px 0 55px 0;
      max-width: 800px;
      margin: 0 auto;
      span {
        display: block;
        height: 2px;
        width: 100px;
        margin: 40px auto 0 auto;
        background: $color-light-sea-green;
      }
    }
    .retinol-1 {
      border: 10px solid $color-light-sea-green;
      background: $white;
      padding: 70px 40px;
      max-width: 550px;
      margin: 0;
      @include breakpoint($landscape-up) {
        margin: 130px 0 0 0;
      }
      li {
        margin: 0 0 0 15px;
        float: left;
        &:before {
          content: '\2022';
          margin: 0 10px 2px 0;
          color: $color-light-sea-green;
          font-weight: bolder;
          float: left;
          display: block;
        }
        span {
          float: left;
          width: 90%;
        }
      }
    }
    .retinol-2 {
      text-align: center;
      h2 {
        text-transform: uppercase;
        padding: 0 0 20px 0;
        font-size: 24px;
      }
      .sub-retinol-plus {
        display: block;
        width: 60px;
        height: 60px;
        background-image: url('/media/export/cms/retinol/plus.png');
        margin: 0 auto 20px auto;
      }
      @include breakpoint($landscape-up) {
        margin: 0 20px 0 470px;
      }
    }
  }
}

.content-block-large--split-width {
  @include breakpoint($landscape-up) {
    margin-bottom: 30px;
  }
  &.order-text-image {
    .content-block-large__picture {
      @include breakpoint($landscape-up) {
        order: 1;
      }
    }
  }
  .content-block-large__inner {
    @include breakpoint($landscape-up) {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }
    .kinesio_video & {
      @include breakpoint($landscape-up) {
        padding: 0 10%;
      }
    }
  }
  .content-block-large__picture {
    @include breakpoint($landscape-up) {
      flex: 0 0 265px;
    }
  }
  .content-block-large__content {
    @include breakpoint($landscape-up) {
      position: static;
    }
  }
}

.parisian_carousel {
  .content-block-large {
    &__content {
      background-size: cover;
      padding: 19%;
      @include breakpoint($medium-up) {
        padding: 0;
      }
      @include breakpoint($landscape-up) {
        min-height: 420px;
      }
    }
    &__content-text {
      @include breakpoint($medium-up) {
        min-height: 330px;
      }
    }
  }
}

.landing-callout {
  background-color: $color-light-green-transparent;
  max-width: #{$max-width-num - 100}px;
  margin: 0 auto;
  padding: 25px 10px;
  @include breakpoint($medium-up) {
    padding: 25px 140px;
    margin: 40px auto;
  }
  .content-flex {
    display: flex;
  }
  h3 {
    text-align: center;
  }
  ul.checkboxes {
    margin: 20px 0 0 0;
    li {
      font-weight: bold;
      background-image: url('/media/export/cms/retinol/tick.png');
      background-position: 0 5px;
      background-repeat: no-repeat;
      padding: 10px 0 5px 35px;
      @include breakpoint($medium-up) {
        padding: 10px 60px 5px 35px;
      }
    }
  }
}

.landing-retinol__text {
  text-align: center;
  @include breakpoint($landscape-up) {
    padding: 23px 33px;
    display: block;
    border: 5px solid $color-light-sea-green;
    width: 80%;
    margin: 0 auto;
    border-top: none;
    .button {
      background-color: $white;
      margin-top: 20px;
      &:hover {
        color: $black;
      }
    }
  }
}

.landing-bordered {
  text-align: center;
  @include breakpoint($landscape-up) {
    background: url(/media/export/cms/retinol/capsule.png);
    background-repeat: no-repeat;
    background-position: right 200px;
  }
  .landing-bordered--wrapper {
    @include breakpoint($landscape-up) {
      border: 5px solid $color-light-sea-green;
    }
    max-width: 1200px;
    margin: 0 auto;
    min-height: 720px;
    p {
      max-width: 900px;
      margin: 10px 20px;
      @include breakpoint($landscape-up) {
        margin: 0 auto;
      }
    }
    .side-block {
      background: $white;
      box-shadow: 5px 0px 12px -4px $color-light-gray;
      margin: 100px 20px 0 20px;
      padding: 50px;
      max-width: 100%;
      @include breakpoint($landscape-up) {
        margin: 100px 0 0 -50px;
        padding: 70px 80px 70px 150px;
        max-width: 500px;
      }
    }
  }
}

h2.centered-green {
  color: $black;
  padding: 40px 20px 20px 20px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  .retinol-landing__wrapper & {
    max-width: 1040px;
    border: 5px solid $color-light-sea-green;
    border-bottom: none;
  }
}

.side-block-2,
.side-block-3 {
  background: white;
  max-width: 400px;
  text-align: center;
  box-shadow: 0px 0px 30px 3px $color-lighter-gray;
  margin: 0;
  @include breakpoint($landscape-up) {
    margin-left: 700px;
  }
  &.retinol {
    margin-left: 0px;
    @include breakpoint($landscape-up) {
      margin-left: 700px;
    }
  }
  b {
    padding: 2px;
    background: $color-light-sea-green;
  }
}

.side-block-2 {
  padding: 50px 50px;
  margin-top: 0;
  @include breakpoint($landscape-up) {
    margin-top: 440px;
  }
}

.side-block-3 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 70px 5px 70px;
  margin-top: 30px;
  @include breakpoint($landscape-up) {
    margin-top: 570px;
  }
}

.retinol {
  .landing-callout {
    padding: 25px 20px;
    @include breakpoint($landscape-up) {
      padding: 25px 140px;
    }
    .content-flex {
      display: block;
      @include breakpoint($landscape-up) {
        display: flex;
      }
    }
  }
}

.retinol-side-block--2,
.retinol-side-block--3 {
  margin-top: 0px;
  margin-left: 0px;
}

.retinol-side-block--2 {
  @include breakpoint($landscape-up) {
    margin-top: 440px;
    margin-left: 700px;
  }
}

.retinol-side-block--3 {
  @include breakpoint($landscape-up) {
    margin-top: 570px;
    margin-left: 700px;
  }
}

//disable bottom padding when inside a basic-carousel
.basic-carousel-formatter {
  .content-block-large {
    margin-bottom: 0;
    &__content {
      padding-bottom: 3%;
    }
    &__inner {
      margin-bottom: 0;
    }
    @include breakpoint($medium-up) {
      margin-bottom: 70px;
      &__content {
        padding-bottom: 8%;
      }
      &__inner {
        margin-bottom: 15px;
      }
    }
  }
}
