.adv-cal-cap {
  &__wrapper {
    max-width: 960px;
    margin: 0 auto;
    background-color: $color-white;
    padding-bottom: 110px;
    .cards {
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: center;
      justify-content: center;
      counter-reset: card;
      background-color: $color-cyan-green;
      margin: 0 30px;
      .card {
        -webkit-flex: 1 auto;
        flex: 1 auto;
        position: relative;
        width: 100%;
        max-width: 200px;
        height: 200px;
        margin: 10px;
        padding: 10px;
        background-color: $color-cyan-green;
        border: 1px solid $color-white;
        @include breakpoint($medium-up) {
          width: 100%;
        }
        a {
          color: $color-black;
        }
        &:not(.tout) {
          border: 1px solid $color-white;
          background-color: $color-cyan-green;
          &:before,
          &:after {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            font-family: $main-font;
            color: $color-white;
            font-variant: small-caps;
            letter-spacing: 2px;
            padding-bottom: 2px;
            font-weight: bold;
            text-rendering: auto;
            text-align: right;
            font-size: 39px;
          }
          &:after {
            content: counter(card);
            counter-increment: card;
            top: 15px;
            padding-right: 10px;
          }
        }
        &__content {
          display: table;
          width: 100%;
          height: 100%;
          table-layout: fixed;
          .card__day-header {
            position: absolute;
            top: calc(50% - 30px);
            left: 0;
            text-align: center;
            padding: 0 10px 2px 10px;
            width: 100%;
            font-size: 22px;
            font-variant: small-caps;
            letter-spacing: 2px;
            font-weight: normal;
            text-rendering: auto;
          }
          .card__offer-img-bkg {
            opacity: 0;
            position: relative;
            z-index: 2;
            background-color: $color-white;
            text-align: center;
            display: table-caption;
            vertical-align: middle;
            height: 100%;
          }
        }
        &.expired {
          &:before,
          &:after {
            color: $color-light-gray-adv;
          }
          &:hover {
            cursor: default;
            box-shadow: none;
          }
        }
        &.active {
          border-width: 2px;
          &:hover {
            cursor: pointer;
            background-color: $color-white-transparent;
          }
        }
        &.future {
          &:hover {
            cursor: default;
          }
        }
        &.tout {
          background-color: $color-white;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        &.tout-1-2 {
          max-width: calc((200px * 2) + (2 * 10px));
        }
        &.tout-1-3 {
          max-width: calc((200px * 3) + (4 * 10px));
        }
      }
    }
    .cards break {
      flex-basis: 100%;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
  &__container {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .error-content-wrapper {
      display: none;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: $color-dark-black-transparent-seven;
      z-index: 1000;
      .error-content {
        position: absolute;
        top: 50%;
        left: 50%;
        display: table-cell;
        vertical-align: middle;
        max-width: 700px;
        width: 90%;
        min-height: 300px;
        padding: 10% 2em;
        box-shadow: 0px 2px 6px $color-dark-black;
        border-radius: 3px;
        font-size: 2em;
        background: $color-white;
        text-align: center;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .error-close {
        position: absolute;
        top: 0px;
        right: 0px;
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 2px;
        transition: ease 0.25s all;
        -webkit-transform: translate(50%, -50%);
        transform: translate(50%, -50%);
        border-radius: 100%;
        background: $color-dark-black-transparent-eight;
        text-align: center;
        color: $color-white;
        &:before,
        &:after {
          content: ' ';
          position: absolute;
          top: 4px;
          left: 14px;
          height: 22px;
          width: 2px;
          background-color: $color-white;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
        &:hover {
          -webkit-transform: translate(50%, -50%) rotate(180deg);
          transform: translate(50%, -50%) rotate(180deg);
          background: $color-dark-black;
          text-decoration: none;
        }
      }
    }
    .loader {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $color-white;
      z-index: 99;
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 29px);
        left: calc(50% - 29px);
        display: block;
        width: 50px;
        height: 50px;
        border: 4px solid $color-lighter-gray;
        border-top: 4px solid $color-black;
        border-radius: 50%;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        z-index: 100;
      }
    }
  }
  &__header {
    position: relative;
  }
  &__image-wrap {
    text-align: center;
  }
  &__title {
    @include centerer(true, false);
    top: 55px;
    color: $color-cyan-green;
    text-align: center;
    font-family: $main-font;
    font-size: 33px;
    font-weight: bold;
    line-height: 40px;
  }
  &__popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1000;
    .popup-content {
      &__wrapper {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 542px;
        width: 90%;
        box-shadow: 0px 2px 50px $color-dark-black;
        background: $color-white;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .messages {
          display: none;
          text-align: center;
          border-bottom: 0 none;
        }
      }
      &__wrapper-inner {
        margin: 7% 5%;
        border: 7px solid $color-cyan-green;
      }
      &__info {
        padding: 40px 60px;
        position: relative;
        text-align: center;
        min-height: 230px;
        z-index: 201;
        h3 {
          margin-bottom: 20px;
        }
        p {
          font-size: 15px;
        }
        .day-offer__img {
          margin: 0 0 20px 0;
          display: block;
        }
        .btn-primary {
          position: absolute;
          bottom: 20px;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
      &__close {
        position: absolute;
        top: 2%;
        right: 1%;
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 2px;
        text-align: center;
        color: $color-white;
        z-index: 201;
        &:before,
        &:after {
          content: ' ';
          position: absolute;
          top: 4px;
          left: 14px;
          height: 15px;
          width: 2px;
          background-color: $color-black;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
        &:hover {
          text-decoration: none;
        }
      }
      &__decoration {
        .bridal-ribbon-top,
        .bridal-ribbon-bottom {
          position: absolute;
          z-index: 200;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .bridal-ribbon-top {
          background-image: url('/media/export/cms/offers/advent_calendar/adv_cal_bridal-ribbon-top.png');
          background-position: 0 top;
          left: 0;
          top: 0;
        }
        .bridal-ribbon-bottom {
          background-image: url('/media/export/cms/offers/advent_calendar/adv_cal_bridal-ribbon-bottom.png');
          background-position: 0 bottom;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
