.sitewide-ie-upgrade {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: $color-light-black-transparent;
  &__content {
    z-index: 100;
    background-color: $color-white;
    color: $color-black;
    width: 60%;
    max-width: 720px;
    top: 30%;
    left: 20%;
    position: relative;
  }
  &__copy {
    padding: 25px 30px;
    font-size: 14px;
    text-align: center;
    .button {
      color: $color-black;
      &:hover {
        color: $color-white;
      }
    }
  }
  &__title {
    color: $color-black;
    font-size: 24px;
    @include breakpoint($landscape-up) {
      font-size: 26px;
    }
  }
  &__close {
    color: $color-black;
    position: absolute;
    top: 8px;
    right: 10px;
    display: block;
    font-size: 22px;
    cursor: pointer;
  }
}
