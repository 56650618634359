.content-block-formatter {
  position: relative;
  width: 100%;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  @include breakpoint($landscape-up) {
    margin-top: 30px;
    &__wrapper {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
  }
  .row {
    display: flex;
    justify-content: space-around;
  }
}
