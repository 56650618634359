$gnav-signup-max-width: 380px;

.site-email-signup {
  &__title {
    text-align: center;
  }
  &__subtitle {
    text-align: center;
    font-style: italic;
  }
  &__copy {
    text-align: center;
  }
  &__messages {
    text-align: center;
  }
  &__message {
    &--error {
      color: $color-error;
    }
    &--success {
      color: $color-light-green;
    }
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
  }
  input[type='email'].site-email-signup__field,
  input[type='tel'].site-email-signup__field {
    @include input-placeholder {
      color: $color-light-gray;
    }
  }
  &__terms-conditions {
    display: none;
    position: relative;
    &__checkbox,
    &__more {
      margin-bottom: 20px;
    }
  }
  &__success {
    position: relative;
    text-align: center;
    padding: 2em 0;
    height: 100%;
    overflow: hidden;
    &-header {
      text-transform: capitalize;
      font-size: 30px;
      color: $color-darker-green;
    }
    &-subheader {
      font-size: 20px;
      padding-top: 20px;
    }
    &-msg {
      font-size: 20px;
      padding: 10px;
      text-align: center;
    }
    &-offer {
      line-height: 4.5em;
      font-size: 20px;
      span {
        font-weight: bold;
      }
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 2em;
      margin-bottom: 2em;
      font-size: 13px;
      line-height: 6.5em;
    }
    &-cta {
      margin-bottom: 2em;
      .button--medium {
        min-width: 172px;
      }
    }
  }
  padding: 22px 28px 20px 24px;
  position: relative;
  &__referer {
    text-transform: uppercase;
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  &__header {
    margin-bottom: 18px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  &__error {
    margin-bottom: 3px;
    color: $color-error;
  }
  &__field {
    width: 100%;
    margin-left: 0;
    text-transform: uppercase;
    font-size: 12px;
  }
  input[type='submit'] {
    width: 100%;
    margin: 10px 0px;
  }
  .optional-info__header {
    text-indent: 0;
    margin: 0 0 10px 0;
    padding: 25px 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  .day-of-birth__collection-field {
    select {
      width: 36%;
      &.select-year {
        display: none;
      }
    }
  }
  .sign-in__birth-month-field:after {
    content: '/';
  }
  &__terms {
    text-align: left;
  }
  .disabled,
  .disabled:hover {
    background-color: $color-grey;
    color: $color-black;
    cursor: default;
  }
  &__privacy-policy-copy {
    width: 100%;
    margin: 10px 0;
    text-align: $ldirection;
    font-size: 12px;
    p {
      font-size: 12px;
    }
    .site-footer & {
      font-size: 15px;
      line-height: 1.08;
      margin-top: 15px;
      p {
        font-size: 15px;
      }
    }
  }
}

input[type='email'].email-signup__field {
  margin-right: 1%;
  width: 70%;
  font-size: 13px;
}

input.email-signup__submit {
  min-width: 0px;
  width: 29%;
}

//welcome popup styling

.welcome15-popup {
  width: 100%;
  margin-right: 24px;
  @include breakpoint($landscape-up) {
    height: 60px;
    width: 240px;
    float: right;
  }
  .site-email-signup {
    text-align: center;
    position: relative;
    @include breakpoint($landscape-up) {
      top: -85px;
      padding: 0px;
    }
    &__header {
      font-size: 15px;
      text-transform: lowercase;
      font-weight: normal;
      margin-bottom: 0px;
      padding: 15px 20px;
      background-color: $color-lighter-grey;
      border-bottom: none;
      visibility: hidden;
    }
    &:hover {
      .site-email-signup__header {
        visibility: visible;
      }
    }
    &__submit {
      margin-bottom: 0px;
      width: 27%;
    }
    input[type='text'] {
      width: 70%;
    }
    &__error {
      color: $color-error;
      font-size: 14px;
      padding: 5px;
      margin-bottom: 0;
    }
  }
}
