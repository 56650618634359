#loyalty__panel__have-question {
  @extend %loyalty-panel;
  display: block;
  clear: both;
  padding: 50px;
  text-align: center;
  width: 100%;
  .loyalty__panel__have-question__title {
    margin: 1em;
  }
  &.is-member {
    display: none;
  }
}
