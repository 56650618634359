.email-signup__content,
.contact-us__content {
  margin-bottom: 6%;
  input {
    &[type='email'],
    &[type='number'],
    &[type='password'],
    &[type='search'],
    &[type='tel'],
    &[type='text'],
    &[type='time'],
    &[type='url'] {
      padding: 19px 12px;
      border: 2px solid $color-lighter-gray;
    }
  }
  .form-submit {
    margin-top: 20px;
    width: 150px;
  }
  .form-type-textfield {
    float: left;
    margin: 10px 3% 10px 0;
    width: 47%;
  }
}

.email-signup__header {
  max-width: 1250px;
  margin: 20px auto;
  overflow: hidden;
  padding: 0 20px;
  .email-signup__title {
    font-size: 28px;
    margin: 25px 9px 12px;
    text-align: center;
    line-height: 28px;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .email-signup__subtitle {
    text-align: center;
  }
}

.contact-us__content {
  .resizable-textarea {
    width: 90%;
    .grippie {
      display: none;
    }
  }
  textarea {
    padding: 25px 12px;
    border: 2px solid $color-lighter-gray;
  }
  .form-item-form-Email-Address {
    float: none;
  }
  .form-item {
    &.form-type-radio {
      display: inline;
      margin-right: 20px;
      padding: 5px;
    }
    &.form-type-radios {
      padding: 20px 0;
    }
  }
}

.contact-us__header,
.contact-us-thanks-header {
  max-width: 1185px;
  overflow: hidden;
  padding: 0 20px;
  margin: 0 auto;
}

.site-header__main {
  .site-header__section {
    &.site-header__section--nav_promo {
      display: none;
    }
  }
}

.gnav-util-formatter {
  &__item {
    padding-top: 5px;
  }
}

.security-privacy,
.security-terms {
  a[name] {
    display: block;
    padding-top: 165px;
    margin-top: -165px;
  }
}

.store-locator {
  &__content {
    padding-bottom: 7%;
    .store_results {
      padding-top: 2%;
      text-align: center;
    }
  }
}

.site-email-signup {
  &__terms {
    padding: 10px 0;
  }
}

body {
  .content-container {
    max-width: 1300px;
  }
}
