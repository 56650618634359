.contact-us {
  &__salesforce {
    input[type='text'],
    input[type='email'] {
      padding: 17px 12px;
      border: 2px solid $color-lighter-gray !important;
      height: 32px;
      width: 100%;
      line-height: normal;
      @include breakpoint($landscape-up) {
        height: 40px;
      }
    }
    input[type='checkbox'] {
      display: none;
    }
    .label-error {
      margin-bottom: 10px;
      color: $color-red;
    }
    p {
      font-size: 15px;
      margin: 1em 0;
      font-weight: normal;
      padding: 0;
      color: $color-dark-gray;
      &.contact-us__salesforce-hint {
        font-size: 13px;
        margin: 0 20px;
        @include breakpoint($landscape-up) {
          text-align: center;
          font-size: 14px;
          margin: 0 0 1em 0;
        }
      }
    }
    &-form {
      margin: 0 20px;
      @include breakpoint($landscape-up) {
        margin: 0 0 6% 6%;
      }
    }
    &-title {
      font-size: 26px;
      font-family: $medium-font-family;
      font-weight: normal;
      margin: 0 20px;
      @include breakpoint($landscape-up) {
        text-align: center;
        margin: 0;
        font-size: 30px;
      }
    }
    .form-item {
      width: 100%;
      float: left;
      @include breakpoint($landscape-up) {
        margin: 10px 3% 10px 0;
        width: 47%;
      }
      label {
        @include breakpoint($landscape-up) {
          display: block;
        }
        &.error {
          color: $color-red;
          & + input,
          & + textarea {
            border-color: $color-red;
            color: $color-red;
          }
        }
      }
      &__newsletter {
        margin-top: 0;
        text-transform: uppercase;
        width: 90%;
      }
      &__email {
        float: left;
      }
      &__description {
        width: 94%;
        label {
          margin-bottom: 10px;
        }
        textarea {
          width: 100%;
          height: 110px;
          border: 2px solid $color-lighter-gray;
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
      }
    }
    .form-type-radio {
      @include breakpoint($landscape-up) {
        width: auto;
        padding: 5px;
      }
      label {
        display: inline-block;
        margin-right: 20px;
      }
    }
    .form-submit {
      margin-top: 20px;
      width: 150px;
    }
    &-left-content {
      float: left;
      padding-top: 20px;
      width: 100%;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      .form-type-radio {
        margin-bottom: 0;
      }
      .contact-form {
        &__section--question-type-name {
          margin-bottom: 0;
        }
      }
    }
    &-right-content {
      float: left;
      margin-bottom: 20px;
    }
  }
  label {
    font-size: 12px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
}

.contact-form {
  &__section {
    &--question {
      clear: both;
      &-type-name {
        width: 100%;
        float: left;
        margin-bottom: 10px;
      }
    }
  }
}
