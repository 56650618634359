///
/// @file template_api/endeca/_esearch_forms.scss
///
/// \brief Search Block for site header area; form on search page
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

// @todo check main variables, see if we need them here

$mobile_search_header_height: 40px;

.search-form {
  width: 100%;
  &__fields {
    @include pie-clearfix;
  }
  .product-brief__cta--shop-now {
    bottom: 10px;
    @include breakpoint($landscape-up) {
      bottom: 0;
    }
    .button {
      width: 92%;
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
  img {
    display: block;
    margin: 0 auto 20px auto;
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
  }
  input[type='text'].search-form__field {
    width: 100%;
    background: transparent;
    height: auto;
    padding: 0;
    text-transform: uppercase;
    &:focus {
      border-color: $color-black;
    }
    @include breakpoint($landscape-up) {
      border: 1px solid $color-black;
      //width: $pc-search-width;
      padding: 0 7px;
      float: left;
      &:focus {
        border-color: $color-black;
      }
    }
  }
  input[type='submit'].search-form__submit {
    display: block;
    margin: 0 0 0 5px;
    float: left;
  }
  .typeahead-wrapper {
    text-align: left;
    width: 100%;
    background: $white;
    z-index: 999;
    border-bottom: 1px solid $color-light-grey;
    @include breakpoint($landscape-up) {
      max-width: $max-width;
      position: absolute;
      top: 102px;
      left: -50px;
    }
  }
  .search-suggestions__link {
    font-size: 14px;
    display: block;
    padding: 0 7px 0 0;
    @include breakpoint($landscape-up) {
      padding: 0 5em;
    }
  }
  .result {
    padding: 1em 0;
    border-bottom: 1px solid $color-light-grey;
    position: relative;
    @include breakpoint($landscape-up) {
      padding: 1em;
      text-align: center;
      border-bottom: none;
    }
    &.product {
      padding: 1em 0em 4em 0em;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &--results {
    margin: 0 auto;
    position: relative;
    // background-color: #fff;
    min-height: $mobile_search_header_height; // Same height as .search-results__header--mobile. We do this so that it completely covers it.
    @include breakpoint($landscape-up) {
      min-height: auto;
    }
    .search-form__results-count {
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      right: auto;
      width: 100%;
      text-align: center;
      padding: 0 12px;
      @include breakpoint($landscape-up) {
        //right: 169px;
        width: auto;
        text-align: left;
      }
    }
    ul.product-results {
      padding: 0 20px;
      @include breakpoint($landscape-up) {
        display: flex;
        padding: 0;
        max-width: 840px;
        margin: 0 auto 30px auto;
      }
    }
    ul.see-results {
      padding: 0 20px 0 20px;
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($landscape-up) {
    padding: 0 20px;
  }
}
// @todo check styles
.search-results__header--mobile {
  background: $color-lighter-gray;
  text-align: center;
  height: $mobile_search_header_height;
  line-height: $mobile_search_header_height;
}

.search-results__header--desktop {
  padding: 0 1em;
  @include breakpoint($landscape-up) {
    display: block;
  }
}

@include breakpoint($landscape-up) {
  .search-results__header--mobile {
    display: none;
  }
}

// @todo check styles
// we may need logic like this?
// .endeca-product-result {
//   .product__button--add-to-bag {
//     display: none;
//   }
//   // &--1 means shaded, &--0 means un-shaded
//   &--0 {
//     @include breakpoint($small-down) {
//       .product__button--add-to-bag {
//         display: inline-block;
//       }
//       .product__button--quickshop {
//         display: none;
//       }
//     }
//   }
// }
