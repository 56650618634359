/* Dropdown control */
@mixin selectBox {
  font-size: $base-font-size;
  text-decoration: none;
  text-align: left;
  min-width: 150px;
  position: relative;
  border: 1px solid $color-lighter-gray;
  border-radius: 0;
  color: $color-dark-gray;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  height: 40px;
  line-height: 37px;
  background: $color-white;
}

%selectBox {
  @include selectBox;
}

a.selectBox {
  @extend %selectBox;
  .selectBox-label {
    padding: 0 50px 0 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  .selectBox-arrow {
    @include icon('caret--down', after); /* Down Arrow */
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 7%;
    min-width: 50px;
    background-color: inherit;
    color: $color-dark-gray;
    &:after {
      top: 40%;
      position: absolute;
      left: 50%;
    }
  }
  &.selectBox-menuShowing {
    .selectBox-arrow {
      @include icon('caret--up', after); /* Up Arrow */
    }
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  position: absolute;
  z-index: 99999;
  min-height: 1em;
  max-height: 200px;
  border: 1px solid $color-lighter-gray;
  background: $color-white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
/* Options */
@mixin selectBox-options {
  font-size: $base-font-size;
  text-decoration: none;
  text-align: left;
  list-style: none;
  display: block;
  padding: 10px 0;
  margin: 0;
  cursor: pointer;
  overflow: auto;
}
%selectBox-options {
  @include selectBox-options;
  li {
    line-height: 30px;
    &:first-child {
      padding-top: 0;
    }
    a {
      display: block;
      color: $color-dark-gray;
      padding: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      text-decoration: none;
      transition: none;
      &:hover {
        background: $color-cyan-green;
        color: $color-white;
      }
    }
    &.selectBox-selected a {
      background: $color-cyan-green;
      color: $color-white;
    }
    &.selectBox-disabled a {
      color: $color-lighter-gray;
      &:hover {
        background: $color-white;
        cursor: default;
      }
    }
  }
}

.selectBox-options {
  @extend %selectBox-options;
}
