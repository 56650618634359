.session-idle-popup {
  &__content {
    text-align: center;
  }
  &__header {
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  &__desc {
    margin-bottom: 15px;
  }
  .button {
    width: 100%;
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      width: auto;
      margin-bottom: 0;
    }
  }
}
