$advent-calendar-max-width: 1400px;

.adv_cal_cap {
  margin-top: 40px;
  #adv_cal_cap_container {
    .cards {
      .future {
        animation: none;
        pointer-events: none;
      }
    }
    .popup-content-wrapper {
      box-shadow: none;
      border: 3px solid $color-beige;
      background: $white url('/media/export/cms/advent-calender-2021/popup-bg-image.jpg') no-repeat;
      .offer-details {
        padding-top: 10px;
        @include breakpoint($landscape-up) {
          padding-top: 20%;
        }
        &__panel {
          padding-bottom: 30px;
          .offer-details {
            &__img {
              width: 100%;
              float: none;
              text-align: center;
              @include breakpoint($landscape-up) {
                margin: auto;
                width: 50%;
              }
            }
            &__content {
              float: none;
              h3 {
                font-size: 17px;
                margin-bottom: 10px;
              }
              p {
                line-height: initial;
                font-size: 12px;
                font-weight: bold;
              }
            }
            &__controls {
              float: none;
              text-align: center;
              .button {
                background: $color-white;
                color: $color-black;
                border: 1px solid $color-black;
                font-size: 14px;
                font-weight: 600;
                width: 50%;
                height: auto;
                padding: 10px 30px;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .adv-cal-cap-popup {
      .popup-close {
        top: 20px;
        #{$rdirection}: 20px;
        background: none;
        &::before,
        &::after {
          background-color: $color-black;
          height: 25px;
        }
      }
    }
  }
}

.video-block {
  .videojs-video {
    padding-top: 53.5%;
    &__play {
      display: none;
    }
    &__landing {
      &__inner {
        justify-content: flex-start;
        background: $white;
      }
      &__image {
        min-width: 100%;
      }
    }
  }
}

.content-container {
  max-width: none;
  .hero-large__wrapper {
    .content-over-media__text {
      .mantle-custom-text {
        .content-header {
          text-align: center;
          font-weight: 800;
          color: $white;
          font-size: 20px;
          margin: 120px auto 10px;
          @include breakpoint($medium-up) {
            font-size: 32px;
            margin: 80px auto 20px;
          }
        }
        .content-body {
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          text-align: center;
          color: $white;
          padding: 5px 0 0;
          font-size: 10px;
          line-height: 1.5;
          @include breakpoint($medium-up) {
            padding: 5px 0 60px;
            font-size: inherit;
            line-height: inherit;
          }
        }
      }
    }
  }
}

.adv_cal_cap .adv-cal-cap-container .adv-cal-cap-wrapper {
  width: 100%;
  max-width: $advent-calendar-max-width;
  .cards {
    align-items: flex-start;
    & > .card.tile {
      border: none;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      aspect-ratio: 158 / 272;
      height: auto;
      max-width: calc(50% - 2px);
    }

    @include breakpoint($medium-up) {
      padding-top: 0;
      & > .card.tile {
        aspect-ratio: 230 / 246;
        &:not(.tout) {
          border: none;
        }

        &.p2,
        &.p4,
        &.p6,
        &.p7,
        &.p9,
        &.p11 {
          aspect-ratio: 230 / 367;
        }

        &.p7,
        &.p9,
        &.p11 {
          transform: translateY(calc(-33%));
        }
      }
    }
  }
}
