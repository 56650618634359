/**
 * Pagination
 */
.pagination-container {
  .pagination {
    text-align: center;
    padding: 1em 0;
    margin: 0 auto;
    .view-all {
      @include button;
      margin-top: 60px;
      width: 50%;
    }
    .page,
    .next,
    .last {
      display: none;
    }
  }
}
