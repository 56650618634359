// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.
.signin-block {
  padding: 1em 0 0;
  width: 100%;
  text-align: left;
  .elc-user-state-logged-in & {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 1em 0 0;
    }
  }
  &__forms {
    .elc-user-state-logged-in & {
      display: none;
    }
  }
  &--signin {
    .signin-block__form--register {
      display: none;
    }
    .signin-block__form--signin {
      display: block;
    }
  }
  &__form--signin {
    display: none;
  }
  &__main,
  &__footer {
    padding: 0 2em;
  }
  &__title {
    text-align: center;
    font-size: 2em;
    margin: 0;
  }
  &__subtitle {
    font-size: 1.5em;
    line-height: 1;
    text-align: center;
  }
  &__field {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
    &--first-name,
    &--last-name {
      width: 49%;
    }
    &--first-name {
      float: left;
    }
    &--last-name {
      float: right;
    }
  }
  &__toggle-password {
    padding: 4px 0 9px;
    &__option {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      text-decoration: underline;
      &:hover {
        color: $color-link-hover;
      }
      &--hide {
        display: none;
      }
      .signin-block--show-password & {
        &--show {
          display: none;
        }
        &--hide {
          display: inline-block;
        }
      }
    }
  }
  &__checkbox {
    display: block;
  }
  &__footer {
    background-color: $color-light-gray;
    padding: 1em;
  }
  &__forgot-password {
    color: $color-black;
    display: block;
    padding: 11px 0;
  }
  &__submit {
    width: 100%;
    margin-top: 9px;
  }
  &__toggle {
    text-align: center;
    padding: 1em;
    &__cta {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $color-link-hover;
      }
    }
  }
  &__authed {
    @include pie-clearfix;
    display: none;
    @include breakpoint($landscape-up) {
      min-width: 370px;
    }
    &__nav {
      @include breakpoint($landscape-up) {
        min-height: 400px;
        padding: 0 30px 30px;
        float: left;
      }
      &__title {
        padding-left: 20px;
        margin: 0;
        // Wait for js to render the title text as it's raw, user-entered
        // mustache.
        display: none;
        @include breakpoint($landscape-up) {
          font-size: 24px;
          padding: 0;
          line-height: 1;
          margin: 0 0 15px;
        }
        &--rendered {
          display: block;
        }
      }
      &__signout-link {
        font-size: 14px;
        border-top: solid 1px $color-black;
        border-bottom: solid 1px $color-black;
        display: block;
        padding-left: 20px;
        @include breakpoint($landscape-up) {
          border: none;
          display: inline;
          padding: 0;
          line-height: 1;
        }
      }
    }
    &__content {
      @include breakpoint($landscape-up) {
        float: right;
        width: 300px;
        border-left: solid 1px $color-light-gray;
        padding: 0 20px 0 30px;
        min-height: 400px;
      }
    }
    .elc-user-state-logged-in & {
      display: block;
    }
  }
}

// auth states visibility
.elc-user-state-anonymous {
  .hide_anon {
    display: none;
  }
}

.elc-user-state-logged-in {
  .hide_auth {
    display: none;
  }
}

// registration pop up

.site-utils__dropdown {
  .login {
    &__header {
      font-weight: bold;
    }
    &__submit {
      margin-top: 10px;
    }
  }
  .registration {
    &__container {
      padding: 22px 28px 20px 24px;
      text-align: left;
      input,
      submit {
        width: 100%;
        margin-bottom: 15px;
        font-size: 12px;
        &::placeholder {
          text-transform: uppercase;
        }
      }
    }
    &__header {
      margin: 0 0 1em 0;
      font-weight: bold;
    }
    &__form {
      &__header {
        text-indent: 0;
        margin: 15px 0 0 0;
        padding: 5px 0;
        text-transform: uppercase;
        font-weight: 700;
        text-align: left;
      }
      .select-month {
        width: 34%;
      }
      .select-day {
        width: 27%;
      }
      .registration__terms,
      .registration__name,
      .registration__show-pass {
        margin-top: calc(-2.8em - 22px);
      }
      .optional-info {
        &__header {
          font-weight: bold;
        }
      }
    }
    &__terms-text {
      p {
        float: right;
        width: 92%;
      }
    }
    &__email-list {
      display: block;
      clear: both;
      margin: 10px 0;
    }
    &__signin-alt {
      background-color: $color-lighter-gray;
      padding: 10px 0;
    }
    &__have-account {
      padding: 10px 27px;
      font-weight: bold;
    }
    &__login {
      margin: 0 27px;
      width: 84%;
    }
  }
}
