.content-menu {
  max-width: #{$max-width-num - 100}px;
  margin: 0 auto;
  background: $color-light-green-transparent;
  text-align: center;
  padding: 50px 0;
  @include breakpoint($landscape-up) {
    margin-bottom: 100px;
  }
  & li {
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
    margin: 30px 30px 0 0;
    & a {
      text-transform: uppercase;
    }
  }
}

h2.content-menu__header {
  padding: 0 0 5px 0;
  text-transform: uppercase;
}
