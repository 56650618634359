/*
  Colorbox Core Style:
  The following CSS is consistent between example themes and should not be altered.
*/
// @setup new site - this is cleaned up css from the plugin
// alter as needed per brand
// default is from example #3 dark bg, light overlay

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
  @include breakpoint($landscape-up) {
    background: $color-white;
    .colorbox--video & {
      background: none;
    }
  }
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $color-light-black-transparent;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: $color-white;
  padding: 10px;
  @include breakpoint($landscape-up) {
    margin: 30px 20px 20px 20px;
  }
  .qs-overlay-cbox & {
    padding: 0;
  }
  .colorbox--video & {
    padding: 35px 10px;
    background: none;
    color: $color-white;
    @include breakpoint($large-up) {
      padding: 40px 30px;
    }
  }
}

#cboxTitle {
  margin: 0;
  position: absolute;
  top: -20px;
  left: 0;
  color: $color-lighter-gray;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/*
  User Style:
  Change the following styles to modify the appearance of Colorbox.
*/

#colorbox {
  outline: 0;
}

#cboxError {
  padding: 50px;
  border: 1px solid $color-black;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0px;
  color: $color-lighter-gray;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: $color-white;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  //background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  //background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxNext:hover {
  background-position: bottom right;
}

#cboxClose {
  @include icon('close');
  color: $color-black;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: normal;
  font-size: 0; // hides 'close' text generated from plugin script
  @include breakpoint($landscape-up) {
    top: 16px;
    right: 19px;
  }
  &:before {
    font-size: 19px;
    line-height: 19px;
  }
  .colorbox--video & {
    color: $color-white;
    top: 0;
    right: 0;
    padding: 5px 10px;
    @include breakpoint($large-up) {
      right: 20px;
      padding: 0 10px;
    }
    &:before {
      font-size: 22px;
      line-height: 22px;
      @include breakpoint($large-up) {
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
}
