.change-password {
  .error_messages {
    color: $color-red;
    font-size: 14px;
    li {
      p {
        font-size: 14px;
      }
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__header {
          text-align: left;
          font-size: 20px;
        }
        &__item {
          margin: 15px 0;
          input {
            @include box-sizing(border-box);
            width: 100%;
          }
          .button {
            width: 48%;
          }
          .form-cancel {
            float: left;
            margin-right: 4%;
            &:hover {
              border-color: $color-light-green;
            }
          }
          .form-submit {
            padding-left: 0;
            padding-right: 0;
            &.disabled,
            &:disabled {
              border: 1px solid $color-light-gray-adv;
              background-color: $color-white;
              color: $color-light-gray-adv;
              cursor: not-allowed;
            }
          }
        }
        &__rules {
          margin: 10px 0;
          li {
            display: inline-block;
            font-style: italic;
            @include breakpoint($medium-portrait-only) {
              display: flex;
            }
            &:before {
              margin-right: 6px;
              content: $cross;
              display: inline-block;
              color: $color-red;
              font-size: 16px;
              line-height: 0;
            }
            &:after {
              content: ' ';
            }
            &.pass {
              color: $color-green;
              &:before {
                content: $tick;
                color: $color-green;
              }
            }
            &:last-child {
              &:after {
                content: ' ';
              }
            }
          }
        }
        &__meter-status {
          font-weight: bold;
        }
        &__meter {
          height: 8px;
          border-radius: 4px;
          &--container {
            margin-top: 6px;
            background-color: $color-gray;
          }
          &--progress {
            background-color: $color-gray;
            transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
          }
        }
        &__meter-wrapper {
          .profile-info {
            &__strength-list {
              display: inline-block;
              li {
                display: none;
                font-style: italic;
              }
            }
          }
          &.weak,
          &.no-score {
            .profile-info {
              &__strength-list {
                .no-score {
                  display: inline-block;
                }
              }
            }
          }
          &.bad {
            .profile-info {
              &__meter {
                &--progress {
                  width: 25%;
                  background-color: $color-red;
                }
              }
              &__strength-list {
                .bad {
                  display: inline-block;
                }
              }
            }
          }
          &.average {
            .profile-info {
              &__meter {
                &--progress {
                  width: 50%;
                  background-color: $color-average;
                }
              }
              &__strength-list {
                .average {
                  display: inline-block;
                }
              }
            }
          }
          &.good {
            .profile-info {
              &__meter {
                &--progress {
                  width: 75%;
                  background-color: $color-good;
                }
              }
              &__strength-list {
                .good {
                  display: inline-block;
                }
              }
            }
          }
          &.strong {
            .profile-info {
              &__meter {
                &--progress {
                  width: 100%;
                  background-color: $color-green;
                }
              }
              &__strength-list {
                .strong {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  fieldset {
    overflow: visible;
  }
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        position: absolute;
        right: 100%;
        margin-right: 1%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 208px;
        border: 1px solid $color-gray;
      }
      @include breakpoint($medium-portrait-only) {
        left: 85%;
      }
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          left: 100%;
          border: 5px solid transparent;
          border-right-color: $color-gray;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        @include breakpoint($medium-portrait-only) {
          left: -6%;
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }
      &-checkout {
        @include breakpoint($medium-up) {
          width: 205px;
          left: 60%;
        }
        width: 100%;
        &:before {
          @include breakpoint($medium-up) {
            left: -11px;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
        }
      }
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &-reset {
        bottom: auto;
        top: 40%;
        left: 100%;
        @include breakpoint($medium-up) {
          width: 208px;
        }
        @include breakpoint($medium-portrait-only) {
          width: 208px;
          left: 75%;
        }
        &:before {
          left: 0;
          top: 45%;
          transform: translate(-110%, -50%);
        }
      }
      &--nav {
        width: 221px;
        font-size: 12px;
        li {
          padding: 6px 0;
          width: 100px;
        }
      }
    }
    &__rules {
      @include breakpoint($medium-up) {
        column-count: 1;
        font-size: 14px;
      }
      column-count: 2;
      padding: 10px;
      list-style: none;
      font-size: 13px;
      li {
        display: flex;
        align-items: center;
        padding: 3px 0;
        color: $color-gray;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        white-space: nowrap;
        &:before {
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 0;
          margin-right: 6px;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        &.pass {
          color: $color-success-mark;
          &:before {
            color: $color-success-mark;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
    }
    &__popup {
      column-count: 2;
      padding: 0;
    }
  }
}
