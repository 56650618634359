#colorbox.popup-offer-cbox {
  border: 1px solid $color-black;
  margin: 0px;
  @include breakpoint($small-up) {
    border: 3px solid $color-cyan-green;
  }
}

.popup-offer {
  width: 100%;
  height: 100%;
  &__outer-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    text-align: center;
    @include breakpoint($large-up) {
      padding-top: 0;
    }
    .optional-info__header {
      text-transform: uppercase;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-black;
    }
    .sign-in-component__birthday-program {
      text-align: center;
      .sign-in__birth-month-field:after {
        content: '/';
      }
      .day-of-birth__collection-field {
        margin-bottom: 32px;
        @include breakpoint($small-down) {
          margin-bottom: 0;
        }
        select {
          background-position: 96%;
        }
      }
      &-copy {
        font-size: 14px;
        line-height: 1.4em;
      }
      .select-month,
      .select-day {
        width: 45px;
        padding: 10px;
      }
    }
  }
  &__heading {
    margin-bottom: 1em;
    font-family: $medium-font-family;
    font-size: 32px;
    letter-spacing: 6px;
    line-height: 1.2em;
    text-transform: uppercase;
    @include breakpoint($medium-down) {
      font-size: 24px;
      margin-bottom: 0.5em;
    }
  }
  &__body {
    font-size: 16px;
    line-height: 1.75em;
    @include breakpoint($small-down) {
      font-size: 13px;
    }
    a {
      font-family: $medium-font-family;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
    &-terms,
    &-terms p {
      font-size: 11px;
      line-height: 1.5em;
      text-align: left;
      a {
        font-family: $main-font;
        letter-spacing: default;
        text-transform: none;
      }
    }
    &-privacy-policy-copy,
    &-privacy-policy-copy p {
      font-size: 13px;
      line-height: 1.75;
      margin-bottom: 1em;
      @include breakpoint($landscape-up) {
        font-size: 14px;
      }
      a {
        font-family: $medium-font-family;
      }
    }
  }
  .info-message-txt {
    p {
      font-size: 10px;
    }
  }
  &__email-input {
    width: 240px;
    text-transform: uppercase;
    margin-bottom: 10px;
    @include breakpoint($medium-down) {
      margin-bottom: 1em;
      width: 60%;
    }
  }
  &__submit-input {
    margin-top: -3px;
    @include breakpoint($small-down) {
      width: 37%;
    }
    &.disabled,
    &.disabled:hover {
      background-color: $color-grey;
      color: $color-black;
      cursor: default;
    }
  }
  &__footer {
    width: 100%;
    font-size: 11px;
    line-height: 1.5em;
    text-align: center;
    p,
    a {
      margin-bottom: 0;
      font-size: 11px;
    }
  }
  &__sign-up-form {
    .selectbox {
      float: left;
      &.select-month {
        margin-right: 5px;
        width: 34.5%;
        padding-right: 0;
      }
      &.select-day {
        margin-right: 5px;
        width: 28%;
        padding-right: 0;
      }
      &.select-year {
        width: 34.5%;
        padding-right: 0;
      }
    }
    &-error-messages {
      color: $color-error;
      span {
        display: block;
      }
    }
  }
}
