.skin-diagnosis {
  padding: 0 0 120px 0;
  h2 {
    margin: 0 0 20px 0;
    text-align: center;
    @include breakpoint($landscape-up) {
      font-size: 30px;
    }
  }
  h3 {
    width: 100%;
    clear: both;
    margin: 0 0 20px 0;
  }
  .js-error-msg {
    display: none;
  }
  .product-brief__picture-container {
    float: left;
    width: 50%;
    margin: 0 20px 0 0;
    border: 1px solid $color-lighter-gray;
    @include breakpoint($landscape-up) {
      border: none;
      float: none;
      width: auto;
    }
  }
  .product-brief__name,
  .product-brief__price,
  .product__button--add-to-bag {
    text-align: left;
    @include breakpoint($landscape-up) {
      text-align: inherit;
    }
  }
  &__intro {
    display: none;
    h2 {
      margin: 20px 0 0 0;
      text-align: center;
    }
    p {
      text-align: center;
      padding: 0 30px;
    }
  }
  &__menu {
    justify-content: center;
    margin-bottom: 30px;
    display: none;
    & li {
      padding: 0 10px;
      border-right: 1px solid $color-lighter-gray;
      width: 200px;
      &:last-child {
        border-right: none;
      }
      .title,
      .answer {
        text-transform: uppercase;
        font-size: 12px;
        display: block;
        text-align: center;
        line-height: 15px;
      }
      .answer {
        font-weight: bold;
        color: $black;
        min-height: 60px;
        span {
          display: block;
        }
      }
    }
  }
  .texture_question,
  .cleanser_questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  ul.double li {
    float: left;
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 50%;
    }
  }
  ul.triple li {
    float: left;
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 33.33%;
    }
  }
  li {
    font-size: 18px;
    cursor: pointer;
    color: $color-gray;
    &:hover {
      color: $color-dark-grey;
    }
    &.picture {
      margin: 20px 0;
      width: 250px;
      span {
        margin: 0 auto;
        display: block;
        float: none;
        &.title {
          text-align: center;
          margin: 10px 0;
          min-height: 50px;
          display: block;
        }
      }
      img {
        display: block;
        margin: 0 auto;
      }
      @include breakpoint($landscape-up) {
        margin: 20px;
        width: 180px;
      }
    }
    .checkbox {
      border: 1px solid $color-light-gray;
      background: $white;
      width: 22px;
      height: 22px;
      display: block;
      float: left;
      padding: 2px;
      margin: 0 15px 30px 0;
      &.tall {
        @include breakpoint($landscape-up) {
          margin-bottom: 40px;
        }
      }
      span {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    &.ticked {
      .checkbox {
        span {
          background: $color-light-green;
        }
      }
    }
  }
  @include breakpoint($landscape-up) {
    margin-top: 11px;
    padding: 30px 0 150px 0;
    background-image: url(/media/export/cms/skin-diagnosis/skin_diagnosis_bg.png);
    background-repeat: no-repeat;
    background-position: center -360px;
    background-size: 100%;
    position: relative;
  }
  &__nav {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    a {
      display: block;
      background: $color-light-green;
      padding: 15px 0 35px 0;
      width: 180px;
      text-align: center;
      margin: 0 auto;
      text-decoration: none;
      background-image: url(/media/export/cms/skin-diagnosis/caret.svg);
      background-repeat: no-repeat;
      background-position: center 45px;
    }
  }
  &__nav-menu {
    background: $color-light-green;
    margin: 0 20px 20px 20px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__block {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 30px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__landing {
    background-image: url(/media/export/cms/skin-diagnosis/skin_diagnosis_landing_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    padding: 30px;
    @include breakpoint($landscape-up) {
      padding: 70px 105px;
    }
    &-wrapper {
      text-align: center;
      max-width: 660px;
      background: $color-light-green-transparent;
      margin: 0 auto;
      padding: 30px;
      @include breakpoint($landscape-up) {
        padding: 90px 140px 35px 140px;
      }
    }
    img {
      width: 185px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 26px;
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
    }
  }
  &__priorities,
  &__sign-age {
    max-width: 650px;
    margin: 0 auto 150px auto;
  }
  &__eyes {
    max-width: 700px;
    margin: 0 auto 150px auto;
  }
  &__priorities,
  &__sign-age,
  &__eyes {
    margin: 0 auto 330px auto;
    @include breakpoint($landscape-up) {
      margin: 0 auto 150px auto;
    }
  }
  &__result {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 0 0 50px 0;
    padding: 0 0 40px 0;
    border-bottom: 1px solid $color-lighter-grey;
    h2 {
      margin: 0 0 10px 0;
    }
    h3 {
      text-transform: uppercase;
      color: $color-light-grey;
      padding-bottom: 0;
      margin: 0;
    }
    .grid__item {
      width: 90%;
      margin: 20px 0 0 0;
      @include breakpoint($landscape-up) {
        width: auto;
        margin: 0;
      }
    }
  }
  &__start-over {
    text-align: center;
    display: block;
    padding: 0 0 20px 0;
    text-transform: uppercase;
    border-bottom: 1px solid $color-lighter-grey;
    margin: 0 0 30px 0;
    font-weight: bold;
    font-size: 12px;
  }
  &__results-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
