#loyalty__panel__how-to-earn {
  @extend %loyalty-panel;
  clear: both;
  &.is-member {
    display: none;
    #loyalty__page__about & {
      display: block;
    }
  }
  .loyalty__panel__how-to-earn__title {
    @extend %loyalty-panel-title;
  }
}
