.ui-datepicker {
  width: 17em;
  padding: 0.2em 0.2em 0;
  display: none;
  .ui-datepicker {
    &-header {
      position: relative;
      padding: 0.2em 0;
    }
    &-prev,
    &-next {
      position: absolute;
      top: 2px;
      width: 1.8em;
      height: 1.8em;
      &-hover {
        top: 1px;
      }
      span {
        display: block;
        position: absolute;
        left: 30%;
        top: 30%;
      }
    }
    &-prev {
      left: 2px;
      &-hover {
        left: 1px;
      }
    }
    &-next {
      right: 2px;
      &-hover {
        right: 1px;
      }
    }
    &-title {
      margin: 0 2.3em;
      line-height: 1.8em;
      text-align: center;
      select {
        font-size: 1em;
        margin: 1px 0;
      }
    }
    &-buttonpane {
      background-image: none;
      margin: 0.7em 0 0 0;
      padding: 0 0.2em;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      button {
        float: right;
        margin: 0.5em 0.2em 0.4em;
        cursor: pointer;
        padding: 0.2em 0.6em 0.3em 0.6em;
        width: auto;
        overflow: visible;
        &.ui-datepicker-current {
          float: left;
        }
      }
    }
  }
  table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0 0 0.4em;
  }
  th {
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    border: 0;
  }
  td {
    border: 0;
    padding: 1px;
    span,
    a {
      display: block;
      padding: 0.2em;
      text-align: right;
      text-decoration: none;
    }
  }
  select {
    &.ui-datepicker {
      &-month,
      &-year {
        width: 45%;
      }
    }
  }
  &.ui-datepicker-multi {
    width: auto;
  }
  &-multi {
    .ui-datepicker-group {
      float: left;
      table {
        width: 95%;
        margin: 0 auto 0.4em;
      }
      &-last,
      &-middle {
        .ui-datepicker-header {
          border-left-width: 0;
        }
      }
    }
    &-2 {
      .ui-datepicker-group {
        width: 50%;
      }
    }
    &-3 {
      .ui-datepicker-group {
        width: 33.3%;
      }
    }
    &-4 {
      .ui-datepicker-group {
        width: 25%;
      }
    }
    .ui-datepicker-buttonpane {
      clear: left;
    }
  }
  &-row-break {
    clear: both;
    width: 100%;
    font-size: 0;
  }
  .ui-icon {
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
    left: 0.5em;
    top: 0.3em;
  }
}
