#loyalty__panel__invited {
  @extend %loyalty-panel;
  &.is-member {
    display: none;
  }
  @include breakpoint($landscape-up) {
    float: left;
    width: 50%;
  }
  .loyalty__panel__invited__title {
    @extend %loyalty-panel-title;
  }
}
