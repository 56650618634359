.hydraskin-stick {
  .bold {
    font-family: $medium-font-family;
  }
  .content-block-large {
    &__content {
      background-size: contain;
      background-position: top;
      @include breakpoint($medium-up) {
        min-height: 406px;
        margin: 0 auto 110px;
        background-position: center;
      }
    }
    &__content-text {
      flex-direction: column;
      &__header,
      &__content {
        width: 100%;
      }
    }
    &--split-width {
      .content-block-large {
        &__content-buttons {
          margin-top: 33px;
          position: unset;
          @include breakpoint($medium-up) {
            text-align: left;
          }
        }
      }
      .content-block-large__picture {
        @include breakpoint($medium-up) {
          flex: 44%;
          margin-left: 7%;
        }
      }
    }
    &__text {
      text-align: left;
    }
    &__content-text__subheader,
    &__content-text__content {
      font-size: 16px;
      text-align: left;
      padding: 12px 0;
      position: relative;
      @include breakpoint($medium-up) {
        padding: 0 28% 18px 18%;
        &:after {
          content: ' ';
          background-color: $color-cool-blue;
          display: block;
          position: absolute;
          top: 200px;
          height: 9px;
          width: 78px;
        }
      }
    }
    &__content-text__subheader {
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        &:after {
          content: ' ';
          background-color: $color-cool-blue;
          display: block;
          position: absolute;
          top: 350px;
          right: -400px;
          height: 295px;
          width: 500px;
        }
      }
    }
  }
  .eyebrow,
  .blue-back {
    font-family: $medium-font-family;
    font-size: 15px;
    line-height: 1;
    text-transform: uppercase;
    background-color: $color-cool-blue;
    display: inline-block;
    padding: 5px 9px 0px;
    margin-bottom: 9px;
    @include breakpoint($medium-up) {
      font-size: 20px;
      padding: 6px 9px 1px;
    }
  }
  h2 {
    &.hed {
      font-family: $medium-font-family;
      font-size: 40px;
      color: $color-cool-blue;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      @include breakpoint($medium-up) {
        font-size: 60px;
      }
      &.botanicals {
        margin: 0 auto 60px;
      }
    }
    &.homepage-title {
      font-size: 22px;
      @include breakpoint($medium-up) {
        font-size: 30px;
      }
    }
  }
  .basic-responsive {
    text-align: center;
  }
  h3 {
    &.homepage-title {
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 22px;
      }
    }
  }
  .threeinone {
    &.hed {
      margin-top: 10px;
      @include breakpoint($medium-up) {
        margin-top: 100px;
      }
    }
    &.dek {
      font-family: $medium-font-family;
      font-size: 23px;
      text-align: center;
      width: 70%;
      margin: 0 auto;
      @include breakpoint($medium-up) {
        width: 33%;
        line-height: 1.5;
        margin-bottom: 30px;
      }
    }
    &.chart {
      background-color: $color-cool-blue;
      position: absolute;
      top: 320px;
      left: 0;
      padding: 12px 40px 12px 60px;
      width: 100%;
    }
    .hed {
      font-family: $medium-font-family;
      font-size: 20px;
      color: $color-white;
      text-transform: uppercase;
      margin-bottom: 9px;
      @include breakpoint($medium-up) {
        font-size: 34px;
      }
    }
    section {
      @include breakpoint($medium-up) {
        padding: 3% 0 0 68px;
      }
      .item {
        font-size: 12px;
        line-height: 1.25;
        position: relative;
        margin: 0 auto 24px;
        padding-left: 6px;
        @include breakpoint($medium-up) {
          font-size: 20px;
        }
        &:before {
          content: url('/media/export/cms/collections/hydraskin/hydraskin_pc_snowflake.png');
          position: absolute;
          left: -36px;
          top: -6px;
        }
      }
    }
  }
  .purity-hed {
    font-family: $medium-font-family;
    font-size: 30px;
    color: $color-cool-blue;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    margin: 80px 0 0;
  }
  .purity-box {
    font-family: $medium-font-family;
    font-size: 32px;
    color: $color-cool-blue;
    text-transform: uppercase;
    line-height: 1;
    text-align: left;
    padding: 12px 24px;
    margin: 12px 0 0;
    border-left: 4px solid $color-cool-blue;
    position: relative;
    top: -40px;
    @include breakpoint($medium-up) {
      font-size: 22px;
      color: $color-black;
      text-transform: none;
      line-height: 1.25;
      overflow: auto;
      width: 740px;
      margin: 0 auto 110px;
      border: 4px solid $color-cool-blue;
      top: 0;
    }
    .item {
      @include breakpoint($medium-up) {
        width: 44%;
        float: left;
        margin: 0 3%;
      }
    }
  }
  .botanicals {
    &.chart {
      position: absolute;
      top: 43%;
      left: 36%;
      flex-direction: column;
      @include breakpoint($medium-up) {
        top: 12%;
        right: 50px;
        left: unset;
        width: 24%;
        height: 76%;
        display: flex;
      }
      section {
        width: 100%;
        height: 25%;
        padding: 38px 28px 0 0;
      }
    }
    .water {
      font-family: $medium-font-family;
      font-size: 12px;
      color: $color-cool-blue;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
    }
    .ingredient {
      font-family: $medium-font-family;
      font-size: 12px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
    }
    .description {
      font-size: 12px;
      line-height: 1.25;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
    }
  }
  .onthego {
    font-size: 18px;
    text-transform: uppercase;
    position: absolute;
    top: 63%;
    left: 21%;
    padding: 6% 8% 1% 5%;
    @include breakpoint($medium-up) {
      top: 25%;
      left: 28%;
      width: 26%;
      height: 40%;
    }
  }
  .button {
    &:active,
    &:hover {
      color: $color-cyan-green;
    }
  }
}
