/* Mixin function for review star Background image */
@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 18px;
  @each $pr-value in $pr-count {
    .pr-snippet,
    .pr-rd-star-rating,
    .pr-rating-form-group {
      .pr-snippet-stars-png,
      .pr-rating-stars {
        .pr-star-v4-#{$pr-value}-filled {
          // @todo Temporary - the requested svg image is not yet recieved (DAEURO-273). Will update svg instaed of png, Once we received.
          background-image: url('/media/images/global/rating-large-#{$pr-value}.png');
          height: $pr-height-value;
          width: $pr-width-value;
          &:before {
            content: '';
          }
        }
      }
    }
  }
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 35px;
  $pr-width-value: 38px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      // @todo Temporary - the requested svg image is not yet recieved (DAEURO-273). Will update svg instaed of png, Once we received.
      background-image: url('/media/images/global/rating-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* SPP page */
.review-snippet {
  .p-w-r {
    @include pr-background-image();
    margin: 0;
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal,
      &.pr-snippet-compact,
      &.pr-snippet-standard {
        .pr-snippet-read-and-write {
          letter-spacing: 1.8px;
          text-transform: uppercase;
          font-family: $font--text;
          a {
            font-size: 12px !important;
            color: $color-dark-gray;
            box-shadow: none;
            outline: none;
            margin: 0;
            padding: 0 5px;
          }
          .pr-snippet-write-review-link {
            letter-spacing: 0;
            text-transform: capitalize;
            font-size: 11px !important;
            text-decoration: underline;
            text-align: right;
            @include breakpoint($xsmall-up) {
              font-size: 12px !important;
            }
            @include breakpoint($landscape-up) {
              font-size: 12px !important;
              text-align: left;
            }
            @include breakpoint($xlarge-up) {
              font-size: 14px !important;
            }
          }
          .pr-snippet-review-count {
            &:before {
              content: '(';
              visibility: visible;
            }
            &:after {
              content: ')';
              visibility: visible;
            }
          }
        }
        .pr-snippet-stars-container {
          vertical-align: sub;
          display: inline-block;
          .pr-snippet-rating-decimal {
            display: none;
          }
          .pr-snippet-stars-png {
            background: transparent;
            .pr-star-v4 {
              width: 12px;
              height: 10px;
            }
          }
        }
        .pr-snippet-reco-to-friend {
          display: none;
        }
        .pr-snippet-stars-reco-reco {
          margin-top: 5px;
          float: none;
        }
      }
      &.pr-snippet-compact {
        .pr-snippet-stars-reco-reco {
          margin-top: 5px;
        }
      }
      .pr-snippet-stars-reco-stars {
        float: none;
      }
    }
    .pr-no-reviews {
      .pr-snippet {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            display: none;
          }
          .pr-snippet-write-review-link {
            display: inline;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
    .pr-snippet {
      padding: 0 0 8px 0;
      display: inline-block;
      clear: both;
      @include breakpoint($landscape-up) {
        display: table;
        min-height: 40px;
      }
      .pr-category-snippet__total,
      .pr-snippet-read-and-write {
        padding-top: 6px;
      }
      &-rating-decimal {
        display: none;
      }
    }
    section {
      text-align: center;
      display: block;
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
      .mpp-container & {
        text-align: left;
      }
    }
  }
  &.ask-answer--hide {
    .p-w-r {
      .pr-review-snippet-container {
        .pr-snippet-read-and-write {
          .pr-snippet-write-review-link {
            border-right: none;
            right: 0;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

.spp__container {
  .pr-snippet {
    text-align: center;
  }
  .question-processed .p-w-r .pr-qa-snippet-container {
    .pr-snippet-qa-answer-link {
      display: inline;
      @include breakpoint($landscape-up) {
        display: inline-block;
        margin-left: 5px;
        margin-top: 11px;
      }
      @include breakpoint($xlarge-up) {
        margin-top: 9px;
      }
    }
  }
}
/* MPP page */
.mpp__product {
  .review-snippet {
    .p-w-r {
      .pr-no-reviews {
        display: none;
        .pr-category-snippet__total {
          display: none;
        }
      }
      .pr-snippet {
        font-size: 12px;
        line-height: 16px;
        height: 16px;
        margin-top: 5px;
        font-family: $font--text;
        color: $color-light-gray;
        padding: 0;
        .pr-snippet-stars-png {
          .pr-snippet-rating-decimal {
            display: none;
          }
        }
        .pr-category-snippet__total {
          display: none;
          &:before {
            content: '(';
            visibility: visible;
          }
          &:after {
            content: ')';
            visibility: visible;
          }
        }
        .pr-star-v4 {
          width: 12px;
          height: 10px;
        }
      }
      .pr-snippet-stars {
        background: transparent;
      }
    }
  }
}

.mpp {
  .product-brief {
    .p-w-r {
      .pr-category-snippet__item {
        @include breakpoint($landscape-up) {
          display: inherit;
        }
      }
      .pr-snippet {
        .pr-star-v4 {
          width: 12px;
          height: 10px;
        }
        font-family: $font--text;
        font-size: 12px;
      }
      .pr-category-snippet__total {
        &:before {
          content: '(';
          visibility: visible;
        }
        &:after {
          content: ')';
          visibility: visible;
        }
      }
      .pr-no-reviews {
        display: none;
      }
    }
    .pr-category-snippet {
      display: block;
      text-align: center;
    }
    .product_brief__stars,
    .product-brief__reviews-count {
      display: none;
    }
  }
  .product_brief__reviews {
    display: none;
  }
}

.page-spp {
  .p-w-r {
    .pr-review-display {
      background-color: transparent;
    }
  }
}

.spp__reviews_head {
  background-color: $color-gray;
  .p-w-r {
    .pr-review-snapshot,
    .pr-review-display {
      background-color: $color-gray;
    }
    .pr-snippet-stars {
      background-color: transparent;
    }
  }
  .spp-product__reviews {
    width: 800px;
    margin: 0 auto;
    height: auto;
    margin-bottom: 5%;
    @include breakpoint($landscape-up) {
      width: auto;
      padding: 15px;
    }
  }
}

.spp-reviews {
  @include breakpoint($landscape-up) {
    max-width: 1024px;
    width: 100%;
    margin-top: 30px;
  }
  width: auto;
  margin: 0 auto;
  padding: 50px 0;
  &__header {
    text-align: center;
    font-size: 12px;
  }
  .pr-header {
    color: $color-light-gray;
    font-size: 40px;
    font-weight: normal;
    line-height: normal;
    font-family: $font--text;
    text-align: center;
    border-bottom: 1px solid $color-light-gray;
    padding-bottom: 15px;
  }
  .pr-spp__snippet {
    display: none;
  }
  @include breakpoint($landscape-up) {
    .pr-spp__snippet {
      display: block;
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-container {
          display: none;
        }
        .pr-snippet-read-and-write {
          text-align: center;
          margin: 30px 0px 50px;
          .pr-snippet-review-count {
            font-size: 30px;
            font-weight: bold;
          }
          a {
            display: none;
          }
        }
        .pr-snippet-stars-reco-reco {
          display: none;
        }
      }
    }
  }
  &__heading {
    text-align: center;
    letter-spacing: 1px;
    font-size: 21px;
  }
  /* tabing spp page Ask && Answer */
  .tabs-nav {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $color-lighter-gray;
    li {
      color: $color-gray;
      border: 1px solid $color-lighter-gray;
      border-bottom: 0;
      padding: 5px 15px;
      position: relative;
      line-height: 40px;
      top: 1px;
      white-space: nowrap;
      @include breakpoint($med-small-up) {
        padding: 0px 8px;
      }
      @media only(min-width: $medium-up) {
        width: 250px;
        line-height: 50px;
        height: 50px;
        cursor: pointer;
        font-size: 24px;
        text-align: center;
      }
      &.js-pr-rating-tab {
        border-right: 0;
      }
      &.tab-active {
        color: $color-dark-gray;
        border-bottom: 1px solid $color-white;
      }
      &:after {
        position: absolute;
        font-size: 42px;
        width: 14px;
        height: 14px;
        right: 40px;
        top: 3px;
        transition: all 0.4s ease;
      }
    }
  }
  &.ask-answer--hide {
    .tabs-nav {
      border-bottom: none;
      li {
        border: none;
        font-size: 35px;
        width: auto;
        cursor: initial;
        &:last-child {
          display: none;
        }
      }
    }
  }
}
/* To display a Review description posted by user */
#pr-reviewdisplay {
  .p-w-r {
    margin: 0;
    a,
    span,
    select {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .pr-rating-stars {
      float: none;
      text-align: center;
      width: 100%;
      @include breakpoint($landscape-up) {
        float: left;
        width: auto;
        text-align: inherit;
      }
    }
    .pr-review-snapshot-faceoff,
    .pr-review-snapshot-msq-container {
      display: none;
    }
    .pr-review-snapshot {
      @include pr-large-background-image;
      @include breakpoint($landscape-up) {
        border-bottom: 1px solid $color-lighter-gray;
      }
      .pr-review-snapshot-snippets {
        position: relative;
        .pr-snippet-reco-to-friend {
          display: block;
          max-width: initial;
          padding: 0 4px 0px 4px;
          width: 90%;
          margin: 0 auto;
          @include breakpoint($large-up) {
            width: 80%;
          }
          .pr-reco-to-friend-message {
            max-width: initial;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
          .pr-reco-value {
            font-size: 12px;
          }
          .pr-reco {
            margin-right: 0;
            span {
              line-height: 17px;
              margin-right: 0px;
              font-weight: bold;
            }
          }
          div,
          span {
            display: inline !important;
          }
        }
        .pr-snippet-stars-reco-stars {
          .pr-snippet-stars-container {
            display: none;
            @include breakpoint($landscape-up) {
              display: inline-block;
            }
          }
          .pr-snippet-read-and-write {
            .pr-snippet-review-count {
              display: none;
            }
            .pr-snippet-write-review-link {
              @include breakpoint($landscape-up) {
                padding: 8px 16px;
                background-color: $color-white;
                text-decoration: underline;
                position: absolute;
                top: 165px;
                right: 0px;
                width: 100%;
              }
              font-size: 12px !important;
              font-weight: bold;
              text-transform: uppercase;
              width: auto;
              padding: 15px 41px;
              background-color: $color-light-green;
              overflow: visible;
              vertical-align: middle;
              color: $color-black;
              position: relative;
              top: 0px;
              &:hover,
              &:active {
                text-decoration: none;
              }
            }
            margin-top: 7px;
            display: block;
          }
          float: none;
          margin-top: 20px;
          @include breakpoint($landscape-up) {
            margin-top: 0px;
            height: 50px;
          }
          .pr-star-v4 {
            height: 19px;
            width: 23px;
            margin-right: 5px;
          }
          .pr-snippet-stars-png {
            display: block;
          }
          .pr-snippet-rating-decimal {
            display: block;
            border: none;
            background: transparent;
            font-size: 28px;
            color: $color-black;
            float: none;
            margin-left: 0;
            font-family: $font--text;
            padding: 10px 0;
            &:after {
              content: ' out of 5';
              color: $color-lighter-gray;
            }
          }
        }
      }
      .pr-review-snapshot-header-intro {
        display: none;
      }
      text-align: center;
      margin: 0 auto;
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-reco-reco {
          float: none;
          margin-top: 9px;
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
      }
      .pr-review-snapshot-block-pros {
        @include breakpoint($landscape-up) {
          display: block;
        }
        border: none;
        display: none;
        .pr-snapshot-tag-def {
          .pr-filter-btn {
            text-align: center;
            background-color: transparent;
            .pr-filter-btn__voteCount {
              display: none;
            }
            .pr-filter-btn__buttonText {
              font-family: $font--text;
              font-size: 14px;
              text-transform: lowercase;
              color: $color-black;
              letter-spacing: 3px;
              line-height: 20px;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
            .pr-cross-icon {
              right: -12px;
            }
          }
          &:last-child {
            .pr-filter-btn__buttonText {
              &::after {
                content: ' ';
              }
            }
          }
          &:hover {
            background: none;
          }
        }
        .pr-review-snapshot-block-headline {
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          font-family: $font--text;
          padding: 6px 0;
          line-height: 1.2;
          letter-spacing: 1.2px;
          text-transform: uppercase;
          background: none;
        }
        .pr-snapshot-tag-def-filtering {
          padding: 0;
          height: auto;
          line-height: 1;
          margin-top: 0;
        }
        .pr-review-snapshot-tags {
          @include breakpoint($landscape-up) {
            margin-top: 18px;
          }
          dt {
            padding-bottom: 5px;
            width: 70%;
            margin: 0 auto;
            background: none;
          }
        }
      }
      .pr-review-snapshot-block-histogram {
        border: none;
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
        .pr-review-snapshot-block-headline {
          display: none;
        }
        .pr-ratings-histogram-barValue {
          background: $color-blue;
        }
        .pr-ratings-histogram-bar {
          background: $color-lighter-gray;
        }
        .pr-histogram-label {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
          color: $color-gray;
          text-align: left;
        }
        .pr-histogram-count {
          color: $color-gray;
          text-align: left;
        }
      }
      .pr-review-snapshot-block-cons {
        border: none;
        .pr-review-snapshot-tags {
          display: none;
        }
      }
      .pr-rating-stars {
        float: none;
        bottom: 0;
        right: 0;
        @include breakpoint($landscape-up) {
          position: absolute;
          width: 100%;
          top: -40px;
          right: 98%;
        }
      }
      .pr-review-snapshot-block {
        @include breakpoint($landscape-up) {
          width: 31.6%;
          text-align: center;
          min-height: 195px;
        }
        width: 100%;
        min-height: 30px;
        float: left;
        margin-right: 1%;
        border-radius: 2px;
        padding-bottom: 10px;
      }
      .pr-ratings-histogram {
        .pr-histogram-stars {
          height: 36.4px;
          &:active {
            .pr-histogram-label,
            .pr-histogram-count {
              color: $color-gray;
            }
          }
          &:focus {
            box-shadow: none;
          }
        }
        .pr-hist-filter {
          &-5,
          &-4,
          &-3,
          &-2,
          &-1 {
            .pr-ratings-histogram-barValue {
              background-color: $color-black;
              border: none;
            }
            &:hover {
              background-color: $color-black;
            }
          }
        }
      }
      .pr-review-snapshot-content-block {
        margin-bottom: 0;
      }
      .pr-review-snapshot-block-container {
        display: block;
        border-bottom: none;
        @include breakpoint($landscape-up) {
          border-bottom: 1px solid $color-lighter-gray;
          width: 80%;
          margin: 0 auto;
          border: none;
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header.pr-rd-main-header-with-filters {
        margin-bottom: 34px;
        padding-bottom: 51px;
        border-bottom: 1px solid $color-lighter-gray;
        padding-left: 0px;
        padding-right: 0px;
        .pr-rd-review-total {
          display: none;
        }
        .pr-clearfix {
          clear: right;
        }
        .pr-rd-sort {
          font-size: 15px;
          letter-spacing: 1.8px;
          font-family: $font--text;
          color: $color-dark-gray;
          text-transform: uppercase;
          border: solid 1px $color-light-gray;
          padding-right: 0;
          float: right;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          -moz-appearance: none;
        }
        .pr-rd-review-header-sorts {
          padding: 0;
          width: auto;
          float: none;
          @include breakpoint($landscape-up) {
            text-align: right;
            float: $rdirection;
          }
          .pr-rd-sort-group {
            margin: 0 auto;
            width: 80%;
            @include breakpoint($landscape-up) {
              left: 0px;
              margin: 0;
              width: auto;
            }
            position: relative;
            left: -3px;
            top: 5px;
            text-align: right;
            &:last-child {
              padding-top: 0px;
            }
            &:before {
              content: 'sort: ';
              text-transform: uppercase;
              position: relative;
              top: 8px;
              font-family: $font--text;
              font-size: 13px;
              color: $color-dark-gray;
              padding-right: 5px;
              letter-spacing: 1.8px;
              display: none;
              @include breakpoint($landscape-up) {
                display: inline-block;
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          padding: 5px 0 0 0;
          width: 100%;
          text-align: left;
          clear: both;
          margin-top: 2%;
          @include breakpoint($landscape-up) {
            width: 100%;
            margin: 0 auto;
          }
          @include breakpoint($large-up) {
            width: 80%;
          }
          .pr-multiselect-button-skinconcern,
          .pr-multiselect-button-skintype {
            display: none;
            @include breakpoint($landscape-up) {
              display: inline-block;
            }
          }
          .pr-multiselect-button-age {
            button {
              display: none;
              @include breakpoint($landscape-up) {
                display: inline-flex;
                margin-left: 13px;
              }
            }
          }
          .pr-multiselect-button {
            color: $color-light-gray;
            background: none;
            border: 1px solid $color-lighter-gray;
            padding: 6px;
            padding-right: 28px;
            min-width: initial;
            display: inline-flex;
            vertical-align: middle;
            .pr-caret-icon {
              -webkit-appearance: none;
              right: 19px;
              bottom: 15px;
              background-repeat: no-repeat;
              background-position: 6px;
              transform: rotate(270deg);
              svg {
                display: inline-flex;
              }
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
            .pr-multiselect-count {
              background: $color-lighter-gray;
            }
            span {
              font-size: 13px;
              text-transform: uppercase;
              letter-spacing: 0.15em;
            }
          }
          .pr-rd-sort {
            @include breakpoint($landscape-up) {
              border: 1px solid $color-lighter-gray;
              letter-spacing: 1.8px;
              font-size: 13px;
              padding: 0px;
              padding-left: 30px;
            }
            letter-spacing: 0.8px;
            border: none;
          }
          .pr-multiselect-options {
            width: auto;
            max-height: initial;
            overflow: auto;
            border: 1px solid $color-black;
            label {
              display: inline-block;
              span {
                font-size: 12px;
              }
            }
          }
          .pr-multiselect-button-myageis,
          .pr-multiselect-button-age {
            .pr-multiselect-options {
              ul {
                width: 140px;
              }
            }
          }
          .pr-multiselect-button-myskintypeis {
            .pr-multiselect-options {
              ul {
                width: 253px;
              }
            }
          }
          .pr-multiselect-button-myskinconcernis {
            .pr-multiselect-options {
              ul {
                width: 200px;
              }
            }
          }
          .pr-multiselect {
            color: $color-light-gray;
            text-transform: uppercase;
            font-family: $font--text;
            font-size: 13px;
            letter-spacing: 0.4px;
            top: 0;
            position: relative;
            left: 48px;
            @include breakpoint($landscape-up) {
              top: 0;
              left: 0;
            }
            &.pr-multiselect-button-wasthisagift {
              display: none;
            }
            &.pr-multiselect-button-bestuses {
              display: none;
            }
            &.pr-multiselect-button-describeyourself {
              display: none;
            }
            &.pr-multiselect-button-ireceivedafreetrialofthisproduct {
              display: none;
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            display: none;
            .pr-search-icon {
              display: initial;
              svg {
                display: none;
              }
            }
          }
          .pr-rd-search-reviews-input {
            margin-left: 16px;
            @include breakpoint($landscape-up) {
              margin-left: 0px;
            }
            input {
              border: 1px solid $color-lighter-gray;
              margin-top: 11px;
              &:active,
              &:focus {
                + button {
                  svg {
                    path {
                      fill: $color-white;
                    }
                  }
                }
              }
            }
            .pr-rd-search-reviews-clear-button {
              right: 0px;
              top: 11px;
              &:active,
              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }
              svg {
                &:active,
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
                .pr-cross-icon__line {
                  stroke: $color-light-gray;
                }
                g {
                  &:active,
                  &:focus {
                    outline: none;
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
        .pr-rd-main-header-search {
          display: none;
          padding-left: 0;
          width: 30%;
          float: right;
          @include breakpoint($landscape-up) {
            display: block;
            width: 240px;
          }
        }
      }
      .pr-review {
        .pr-rd-header {
          .pr-snippet-rating-decimal {
            display: none;
          }
          .pr-rd-review-headline {
            @include breakpoint($landscape-up) {
              text-align: initial;
              width: 70%;
            }
            text-align: center;
            width: 100%;
            margin: 10px 0 0 0;
            clear: both;
            font-size: 12px;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            font-family: $font--text;
            font-weight: bold;
            color: $color-light-gray;
            padding-top: 2px;
          }
          .pr-snippet-stars-png {
            .pr-snippet-rating-decimal {
              display: none;
            }
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            font-size: 15px;
            color: $color-dark-gray;
            em {
              font-weight: bold;
            }
            @include breakpoint($landscape-up) {
              width: 70%;
            }
          }
          .pr-rd-right {
            .pr-rd-reviewer-type {
              display: none;
            }
            .pr-rd-reviewer-details {
              p,
              time {
                font-size: 12px;
                color: $color-dark-gray;
                margin-bottom: 7px;
              }
            }
          }
        }
        .pr-rd-merchant-response {
          padding: 0 20px;
          .pr-rd-description-text {
            font-size: 15px;
            font-family: $font--text;
            color: $color-dark-gray;
          }
          .pr-rd-merchant-response-headline {
            strong {
              font-size: 15px;
              font-family: $font--text;
              color: $color-dark-gray;
            }
          }
        }
        .pr-accordion {
          text-align: center;
          @include breakpoint($landscape-up) {
            text-align: inherit;
          }
          .pr-accordion-btn {
            padding: 0;
            outline: none;
            box-shadow: none;
            @include breakpoint($landscape-up) {
              padding: 0 30px 0 0;
            }
            span {
              font-size: 12px;
              letter-spacing: 1.8px;
              text-transform: uppercase;
              font-weight: bold;
              color: $color-dark-gray;
            }
            .pr-caret-icon__line {
              stroke: $color-light-gray;
            }
            .pr-caret-icon {
              -webkit-appearance: none;
              background-repeat: no-repeat;
              background-position: 6px;
              transform: rotate(270deg);
              svg {
                display: block;
              }
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                font-size: 12px;
                letter-spacing: 1px;
                color: $color-light-gray;
              }
              dt {
                padding-bottom: 10px;
                &:after {
                  content: ':';
                }
              }
              dt[data-reactid-powerreviews$='wasthisagift.0'] {
                &:after {
                  display: none;
                }
              }
              dd {
                font-size: 12px;
              }
            }
            .pr-rd-tags,
            .pr-rd-subratings {
              width: 95%;
              clear: both;
              dl {
                width: 100%;
              }
              dt {
                padding-left: 0px;
                width: 75%;
                letter-spacing: 1px;
                @include breakpoint($landscape-up) {
                  width: 330px;
                }
              }
              dd {
                display: table-row;
                text-align: left;
              }
            }
            .pr-rd-tags {
              dt {
                float: left;
              }
              dd {
                text-transform: capitalize;
              }
            }
          }
          .pr-rd-subratings {
            width: 95%;
            clear: both;
            dl[data-reactid-powerreviews$='$msq-elistmember'] {
              display: block;
              padding: 15px 0;
            }
            dt[data-reactid-powerreviews$='msq-elistmember.0'] {
              display: none;
            }
            dd[data-reactid-powerreviews$='msq-elistmember.1'] {
              font-size: 15px;
            }
          }
          .pr-rd-tags {
            margin-bottom: 5px;
            .pr-rd-review-tag {
              margin-bottom: 0;
              width: auto;
              dd {
                padding-top: 5px;
                padding-bottom: 5px;
                &::after {
                  content: ',';
                }
                &:last-child {
                  &::after {
                    content: ' ';
                  }
                }
              }
              dt {
                padding-top: 0px;
                padding-bottom: 5px;
              }
              &:nth-child(2),
              &:nth-child(3) {
                display: none;
              }
            }
          }
          &.pr-accordion-exapanded {
            .pr-accordion-btn {
              .pr-caret-icon {
                transform: rotate(90deg);
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            span {
              color: $color-dark-gray;
              font-size: 15px;
            }
            .pr-rd-bold {
              display: none;
            }
            padding-bottom: 15px;
            margin-bottom: 0;
          }
          .pr-rd-helpful-action {
            display: inline-block;
          }
          .pr-helpful {
            &-count {
              font-size: 12px;
              text-transform: uppercase;
              color: $color-dark-gray;
              font-weight: bold;
              &:hover {
                color: $color-dark-gray;
              }
            }
            &-btn {
              border: none;
              padding: 4px;
              background: none;
              text-transform: capitalize;
              vertical-align: top;
              font-weight: bold;
              margin-right: 10px;
              .pr-thumbs-icon {
                display: none;
              }
              &.pr-helpful-active {
                background: none !important;
              }
            }
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: 'Yes';
                  visibility: visible;
                  text-transform: capitalize;
                  font-size: 12px;
                  margin-right: 1px;
                  text-decoration: underline;
                }
                letter-spacing: 0.15em;
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: 'No';
                  visibility: visible;
                  margin-right: 1px;
                  text-transform: capitalize;
                  font-size: 12px;
                  text-decoration: underline;
                }
                letter-spacing: 0.15em;
              }
            }
          }
          .pr-rd-flag-review-container {
            position: relative;
            top: 10px;
            margin-left: 30px;
            display: none;
            a {
              font-size: 12px;
              color: $color-dark-gray;
              text-transform: uppercase;
              font-weight: bold;
              text-decoration: underline;
              font-family: $font--text;
            }
          }
          .pr-modal-content {
            #pr-flag-reviews {
              input[type='radio'] {
                left: auto;
              }
              .pr-flagging-radio {
                span {
                  font-size: 12px;
                }
              }
            }
            .pr-flag-review-btn {
              display: inline-block;
              width: auto;
              padding: 14px 19px 12px;
              overflow: visible;
              vertical-align: middle;
              cursor: pointer;
              color: $color-white;
              font-family: $font--text;
              font-size: 12px;
              text-transform: uppercase;
              font-weight: bold;
              text-decoration: none;
              text-align: center;
              letter-spacing: 0.15em;
              border-radius: 0;
            }
            .pr-flag-review-btn-cancel {
              background: $color-white;
              color: $color-black;
              border: 1px solid $color-light-gray;
            }
            .pr-flag-review-btn-submit,
            .pr-flag-review-btn-submit:hover {
              background: $color-dark-gray;
              border: 1px solid $color-black;
            }
            .pr-control-label,
            .pr-flag-review-label {
              color: $color-black;
              font-family: $font--text;
              font-size: 12px;
              text-transform: uppercase;
            }
          }
        }
        .pr-rd-images {
          margin-bottom: 0;
          .pr-rd-image-container {
            margin: 0 10px 15px 0;
          }
        }
      }
      .pr-rd-main-footer {
        border-top: 1px solid $color-lighter-gray;
        padding-top: 15px;
        .pr-rd-content-block {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: 80%;
            margin: 0 auto;
          }
          .pr-rd-to-top {
            padding-left: 0;
            text-align: center;
            font-size: 15px;
            color: $color-dark-gray;
            font-family: $font--text;
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            font-size: 15px;
            color: $color-dark-gray;
            font-family: $font--text;
          }
        }
      }
      &.pr-rd-display-tablet {
        .pr-rd-side-content-block {
          @include breakpoint($landscape-up) {
            right: -36%;
            top: -55px;
            width: auto;
            text-align: initial;
          }
          width: 100%;
          text-align: center;
          position: inherit;
        }
      }
      .pr-rd-no-reviews {
        font-family: $font--text;
        .pr-snippet-write-review-link {
          text-decoration: underline;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .pr-rd-display-search-no-results {
        clear: both;
        .pr-rd-review-total {
          background: none;
        }
      }
      .pr-rd-main-header-with-filters {
        .pr-rd-main-header-search-sort {
          padding-top: 0;
          @include breakpoint($landscape-up) {
            width: 100%;
            margin: 0 auto;
          }
          @include breakpoint($large-up) {
            width: 80%;
          }
        }
      }
    }
  }
}
/* Global Styles */

/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-brief,
.mpp__product {
  .p-w-r {
    @include pr-background-image();
    margin-left: 0;
    .product-grid-wrapper & {
      float: left;
      margin: 0 0 15px 0;
    }
    a {
      color: $color-dark-gray;
      &:hover {
        color: $color-light-gray;
        text-decoration: underline;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    span,
    p,
    strong {
      color: $color-dark-gray;
      font-family: $font--text;
      font-size: 15px;
      font-weight: normal;
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: 15px;
          font-family: $font--text;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal {
        display: none;
      }
      &-table-cell {
        padding: 0;
      }
      &-helper-text {
        color: $color-light-gray;
        font-family: $font--text;
      }
      &-clear-all-radios {
        color: $color-black;
        letter-spacing: 0;
        &:hover {
          color: $color-light-gray;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-header-table {
        .pr-header-title {
          text-transform: none;
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            display: inline-block;
            a,
            span {
              color: $color-light-gray;
              font-size: 15px;
              font-family: $font--text;
            }
          }
          .pr-snippet-stars-container {
            vertical-align: middle;
          }
          &.pr-snippet-minimal {
            .pr-snippet-read-and-write {
              .pr-snippet-review-count {
                margin: 0 10px 0 10px;
                padding: 0 0px 0 0px;
              }
              a {
                text-decoration: none;
              }
              a:hover {
                color: $color-light-gray;
                text-decoration: underline;
              }
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-light-gray;
          font-family: $font--text;
          font-size: 15px;
        }
        .pr-review {
          @include breakpoint($landscape-up) {
            width: 80%;
            margin: 0 auto;
          }
          .pr-rd-header {
            .pr-rd-review-headline {
              color: $color-dark-gray;
              font-family: $font--text;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }
          }
          .pr-rd-content-block {
            @include breakpoint($landscape-up) {
              width: 70%;
              @include breakpoint($landscape-up) {
                width: 100%;
                clear: both;
                position: relative;
              }
            }
            .pr-rd-side-content-block {
              right: -250px;
              @include breakpoint($landscape-up) {
                position: absolute;
                width: 30%;
                right: 0px;
                top: -30px;
              }
            }
          }
        }
        .pr-rd-description-text,
        .pr-rd-details {
          color: $color-light-gray;
          font-size: 12px;
        }
        .pr-rd-author-nickname {
          span {
            font-size: 12px;
            font-family: $font--text;
            font-weight: bold;
          }
          .pr-rd-bold {
            display: none;
          }
        }
        .pr-rd-author-submission-date {
          time {
            color: $color-light-gray;
            text-transform: uppercase;
          }
        }
        .pr-rd-author-location {
          text-transform: uppercase;
          .pr-rd-bold {
            display: none;
          }
          span {
            font-size: 12px;
          }
        }
        .pr-rd-footer {
          padding: 20px 0 10px 0;
          background-color: $color-lighter-gray;
          text-align: center;
          @include breakpoint($landscape-up) {
            background-color: $color-white;
            text-align: inherit;
          }
        }
        .pr-rd-main-header {
          background: none;
          padding: 0 0 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid $color-lighter-gray;
          h1,
          label {
            display: none;
          }
          .pr-rd-sort {
            font-family: $font--text;
            font-size: 13px;
            color: $color-light-gray;
            border: none;
            background-color: transparent;
          }
          .pr-rd-review-header-contents {
            background-color: transparent;
            @include breakpoint($landscape-up) {
              width: 800px;
              margin: 0 auto;
              float: none;
            }
          }
        }
        .pr-rd-description {
          color: $color-light-gray;
          font-family: $font--text;
          font-size: 12px;
          text-align: center;
          @include breakpoint($landscape-up) {
            text-align: inherit;
          }
        }
      }
    }
    .pr-btn-review {
      background-color: $color-light-gray;
    }
    .pr-btn-review:hover {
      color: $color-white;
    }
    .pr-rd-search-reviews-input {
      input {
        font-family: $font--text;
        font-size: 15px;
        padding-left: 10px;
        border: 1px solid $color-light-gray;
        &::placeholder {
          font-style: italic;
          color: $color-black;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}

#power-review__container {
  .product-full {
    @include breakpoint($landscape-up) {
      width: 1024px;
    }
    &__name {
      font-size: 16px;
      line-height: 23px;
      float: left;
      width: 100%;
      letter-spacing: 0.1px;
      padding-top: 15px;
      text-align: left;
      @include breakpoint($landscape-up) {
        width: 64%;
      }
    }
    &__subheading {
      width: 70%;
      display: inline-block;
      padding-bottom: 10px;
    }
    &__misc-flag {
      text-transform: uppercase;
      text-decoration: none;
    }
    width: auto;
    margin: 0 auto;
    margin-top: 30px;
    .product-full__image {
      width: 50%;
      float: left;
      img {
        width: 250px;
        height: auto;
        float: left;
        margin-right: 30px;
      }
    }
    .product-full__details {
      float: left;
      width: auto;
      @include breakpoint($landscape-up) {
        width: 50%;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

.write-a-review__container {
  #pr-write {
    padding-top: 10px;
    .p-w-r {
      @include pr-background-image();
      .pr-header {
        clear: both;
        border-bottom: none;
        margin: 0;
        .pr-header-title {
          display: none;
        }
        .pr-header-table {
          margin: 0;
        }
      }
      span {
        font-size: 14px;
        font-weight: normal;
        text-shadow: none;
      }
      #pr-war-form {
        clear: both;
        padding-top: 20px;
        #pr-bottomline {
          .btn-group-radio-vertical {
            .pr-btn {
              text-transform: capitalize;
              color: $color-light-gray;
              border-color: $color-light-gray;
              border-bottom: 1px solid $color-black;
              background-color: $color-white;
            }
          }
          .pr-btn-default {
            &:not(.active) {
              &::before {
                content: '';
                border-radius: 50%;
                padding: 5px;
                border: 1px solid $color-text;
              }
            }
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;
          }
        }
        .pr-media_image-form-group,
        .pr-media_videourl-form-group {
          .pr-btn-default {
            @include button;
            min-width: 110px;
            span {
              color: $color-black;
              text-transform: uppercase;
              font-weight: bold;
              letter-spacing: 0.1px;
              font-size: 12px;
              &:after {
                content: ' ';
              }
            }
            &.active {
              background-color: $color-light-gray;
            }
          }
          width: 85%;
          .pr-btn-danger {
            background-color: $color-light-gray;
            border: none;
          }
          .pr-media-preview {
            .form-group {
              display: none;
            }
            .pr-loading-indicator {
              margin: 3% auto;
            }
          }
        }
        .pr-bestuses-form-group,
        .pr-cons-form-group,
        .pr-wasthisagift-form-group,
        .pr-email_collection-form-group,
        .pr-elistmember-form-group,
        .pr-describeyourself-form-group {
          display: none;
        }
        .pr-rating-form-group {
          span {
            font-weight: normal;
            text-transform: uppercase;
          }
        }
        .pr-name-form-group {
          span {
            font-weight: normal;
          }
        }
        .form-group {
          select {
            -webkit-appearance: menulist;
            max-width: initial;
            font-size: 15px;
            text-transform: none;
            font-family: $font--text;
          }
        }
        .pr-control-label {
          span {
            &:nth-child(2) {
              float: left;
              position: relative;
              top: 3px;
            }
          }
        }
        .pr-rating-stars {
          margin-top: 10px;
          position: relative;
          right: 4px;
        }
        .pr-wasthisagift-form-group {
          .pr-btn-default {
            &.active {
              background-color: $color-light-gray;
            }
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
          }
        }
        .input-group {
          .pr-btn {
            background-color: $color-light-gray;
            color: $color-white;
            margin-left: 15px;
            -webkit-border-radius: 0px;
            -webkit-appearance: none;
            border-bottom-width: 1px;
          }
        }
      }
      .pr-clear-all-radios {
        color: $color-light-gray;
      }
      .pr-btn-add-tag {
        a {
          &:after {
            display: none;
          }
          background: $color-cyan-green;
          color: $color-black;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
          font-family: $font--text;
          border: none;
        }
      }
      .pr-btn-fileinput {
        span {
          &:after {
            color: $color-light-gray;
          }
        }
      }
      .pr-submit {
        a {
          font-size: 12px;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
      .form-control {
        color: $color-black;
        box-shadow: none;
        font-size: 15px;
        &::placeholder {
          color: $color-black;
          opacity: 0.4;
          text-transform: none;
          font-size: 15px !important;
        }
      }
      .pr-footer {
        margin-bottom: 60px;
        border: none;
        padding-top: 0;
        .pr-subscript {
          color: $color-black;
          text-transform: Capitalise;
          line-height: 1.4;
          font-size: 14px;
        }
        .pr-btn-default {
          @include button;
          text-transform: uppercase;
          font-size: 12px;
          border: none;
          text-decoration: underline;
          font-family: $font--text;
        }
      }
      .tag-group {
        .pr-label-control {
          &.checked {
            background-color: $color-light-gray;
            .pr-tag-label {
              color: $color-white;
              text-transform: uppercase;
            }
          }
        }
      }
      .thank-you-page {
        .header {
          .title,
          .subtitle {
            color: $color-light-gray;
            font-family: $font--text;
            font-weight: normal;
          }
        }
        .pr-dark {
          font-size: 12px;
          color: $color-light-gray;
          display: inline-block;
          width: 60%;
          font-weight: bold;
          &::after {
            content: ':';
          }
        }
        .pr-text {
          &[data-reactid-powerreviews='.0.0.1.2.3.4'] {
            .pr-dark {
              &::after {
                content: ' ';
              }
            }
          }
        }
        .pr-rating-number,
        .pr-rating-date {
          display: none;
        }
        .review-preview {
          .headline {
            color: $color-light-gray;
          }
          #war-ty-cons,
          #war-ty-best-uses {
            display: none;
          }
          #war-ty-pros {
            .pr-attribute-header {
              background: transparent;
              padding-left: 0;
              color: $color-light-gray;
            }
            .pr-attribute-list {
              .pr-attribute {
                padding-left: 0;
                font-size: 15px;
                color: $color-light-gray;
                font-family: $font--text;
                text-transform: capitalize;
              }
            }
          }
        }
      }
      .pr-has-error {
        .form-control {
          border-color: $color-error;
          &:focus {
            border-color: $color-black;
          }
        }
        .pr-control-label {
          span {
            color: $color-error;
          }
        }
      }
      .btn-group {
        .pr-btn {
          box-shadow: none;
        }
      }
      input[type='text']::placeholder,
      textarea::placeholder {
        font-size: 12px;
      }
      .form-group {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 85%;
        }
        label {
          text-transform: initial;
        }
        &#pr-name,
        &#pr-headline,
        &#pr-location {
          width: 100%;
        }
        #pr-bottomline {
          .pr-helper-text {
            display: none;
          }
        }
      }
      .pr-alert-container {
        &#pr-warning {
          display: none;
        }
        &#pr-alert {
          display: block;
        }
      }
    }
    .pr-table-cell {
      display: block;
      &.pr-header-product-img {
        display: none;
      }
      .pr-header-required {
        display: none;
      }
    }
    .alert-danger,
    .pr-alert-danger {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-black;
      font-family: $font--text;
      p {
        font-family: $font--text;
      }
    }
  }
}

.search-wrapper,
.favorite,
.mpp-container {
  .p-w-r {
    .pr-category-snippet__rating {
      &.pr-category-snippet__item {
        float: left;
      }
    }
  }
}

.favorite {
  .p-w-r {
    .pr-category-snippet {
      &__item,
      &__total {
        text-align: center;
        width: 100%;
        float: left;
      }
    }
  }
}

.pr-review {
  .pr-media-modal {
    .pr-media-carousel {
      .button {
        min-width: 60px;
        background: transparent;
        @include breakpoint($landscape-up) {
          min-width: 160px;
          background: $color-light-green;
        }
      }
    }
  }
}
/* ASk & Answer Implemention */
.question-processed {
  display: block;
  position: relative;
  text-decoration: underline;
  @include breakpoint($landscape-up) {
    display: inline-block;
    position: unset;
  }
  &.pr-ask-question-link-first {
    .p-w-r {
      left: 55%;
      @include breakpoint($medium-up) {
        left: 50%;
      }
    }
  }
  .p-w-r {
    position: absolute;
    top: 35px;
    left: 50%;
    margin: 0;
    padding: 0 0 0 5px;
    line-height: 1;
    @include breakpoint($landscape-up) {
      position: unset;
      padding: 0;
    }
    .pr-qa-snippet-container {
      display: inline-block;
      .pr-snippet-qa-metrics {
        margin-right: 0;
      }
      .pr-snippet-qa-empty {
        .pr-ask-question-link {
          margin-right: 0;
          padding-left: 0;
          @media #{$iphone-only} {
            margin-right: 0px;
          }
          @media only(max-width: $med-small-up) {
            margin-top: 0;
            display: inline;
          }
        }
      }
      .pr-ask-question-link {
        margin-top: 0;
        font-size: 11px;
        color: $color-dark-gray;
        padding-left: 20px;
        font-family: $font--text;
        text-decoration: underline;
        display: inline-block;
        line-height: 2.1;
        @include breakpoint($xsmall-up) {
          font-size: 12px;
          line-height: 1.9;
        }
        @include breakpoint($medium-up) {
          font-size: 12px;
          padding-left: 4px;
        }
        .pr-ask-question-link {
          text-decoration: underline;
        }
        @include breakpoint($landscape-up) {
          margin-left: 5px;
          margin-top: 11px;
        }
        @include breakpoint($xlarge-up) {
          font-size: 14px;
          margin-top: 9px;
        }
      }
      .pr-ask-question-cta,
      .pr-ask-question-subtext {
        display: none;
      }
      .pr-snippet-qa {
        .pr-qa-large,
        .pr-answered-question-circle {
          display: none;
        }
      }
      .pr-snippet-qa-answer-link {
        font-family: $font--text;
        color: $color-dark-gray;
        font-size: 11px;
        text-decoration: underline;
        display: inline-block;
        margin-top: 0;
        padding-left: 0;
        white-space: nowrap;
        line-height: 2.1;
        @include breakpoint($xsmall-up) {
          font-size: 12px;
          line-height: 1.9;
        }
        @include breakpoint($landscape-up) {
          font-size: 12px;
          margin-top: 7px;
          padding-left: 0;
        }
        @include breakpoint($xlarge-up) {
          margin-top: 6px;
          font-size: 14px;
        }
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-review-snippet-container {
      display: inline-block;
      float: left;
      .pr-snippet-stars-reco-inline {
        &.pr-snippet-compact,
        &.pr-snippet-standard,
        &.pr-snippet-minimal {
          .pr-snippet-read-and-write {
            margin-top: 0px;
            padding-top: 0;
            padding-bottom: 16px;
            @include breakpoint($landscape-up) {
              margin-top: 0;
              padding-top: 10px;
              padding-bottom: 0;
            }
          }
        }
        &.pr-snippet-minimal {
          .pr-snippet-stars-reco-reco {
            @media only(min-width: $landscape-up) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  &.pr-ask-question-without-reviews {
    &.js-spp-review-snippet {
      .pr-review-snippet-container {
        .pr-snippet {
          .pr-snippet-stars {
            @media (min-width: $med-small-up) and (max-width: $medium-up) {
              margin-bottom: 0;
            }
            @media only(max-width: $med-small-up) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.js-spp-review-snippet {
  .pr-review-snippet-container {
    .pr-snippet {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      @media only(min-width: $landscape-up) {
        position: inherit;
      }
      .pr-snippet-read-and-write {
        margin-top: 0px;
        a {
          &.pr-snippet-write-review-link {
            display: block;
            position: absolute;
            right: 50%;
            top: 35px;
            padding: 0 5px 0 0;
            border-right: 1px solid $black;
            white-space: nowrap;
            @include breakpoint($landscape-up) {
              display: inline;
              position: inherit;
              padding: 0 5px;
              border-right: 1px solid $black;
            }
            &.firstread {
              right: 45%;
              padding: 0 7px 0 0;
              @include breakpoint($medium-up) {
                padding: 0 5px 0 0;
                right: 50%;
              }
              @include breakpoint($landscape-up) {
                padding: 0 5px;
              }
            }
          }
        }
      }
      .pr-snippet-stars {
        margin-bottom: 26px;
        @media only(min-width: $landscape-up) {
          margin-bottom: 0;
        }
      }
    }
  }
}
/*  Ask & Answer Form section */
.tabs-content {
  .p-w-r {
    .pr-qa-display {
      padding: 0px;
      .pr-qa-display-headline {
        margin:0 10px;
        order: 2;
      }
      h1 {
        display: none;
      }
      &.pr-qa-display-desktop {
        .pr-qa-display-questionlist {
          padding-top: 12px;
        }
        dd {
          margin-left: 0px;
        }
        .pr-qa-display-headline {
          margin-top: 0px;
        }
        .pr-qa-display-ask-question {
          float: none;
          padding: 0 10%;
          #pr-question-form-content-container {
            padding-top: 10%;
          }
        }
        .pr-qa-display-thankyou-headline {
          padding: 0 10%;
        }
        .pr-qa-display-answer-by-container {
          &.pr-qa-display-answer-by-container-unverified {
            .pr-qa-display-answer-details {
              padding-left: 20px;
              border-left: 1px solid $color-dark-gray;
            }
          }
        }
      }
      &.pr-qa-display-tablet {
        .pr-qa-display-ask-question {
          float: none;
          padding: 0 13%;
          .pr-qa-display-btn {
            background-color: $color-white;
            padding: 12px 30px;
            border: 1px solid $color-lighter-gray;
            color: $color-text;
            position: relative;
          }
        }
      }
      .pr-qa-display-ask-question {
        .pr-submit {
          @media only(min-width: $medium-up) {
            float: right;
          }
          .pr-accessible-btn {
            background: $color-cyan-green;
            color: $black;
            padding: 12px 44px;
            -webkit-appearance: none;
            border: none;
            &:hover {
              color: $color-white;
            }
          }
        }
        .pr-question-form {
          font-family: $font--text;
          .p-w-r {
            .btn-group {
              z-index: 9;
            }
          }
        }
        .pr-btn-ask-question {
          .pr-cross-icon {
            display: none;
          }
          &:focus {
            .pr-qa-display-text {
              text-decoration: none;
            }
          }
          span {
            color: $color-text;
          }
        }
        padding: 0 10%;
        @media only(max-width: $medium-up) {
          padding: 0 10px;
        }
      }
      .pr-waqwaa {
        margin: 0px auto;
        width: auto;
        max-width: 100%;
      }
      .form-group {
        .form-control {
          border: 1px solid $color-lighter-gray;
        }
        .pr-helper-text,
        .pr-required-indicator {
          color: $color-text;
        }
        .pr-control-label {
          span {
            color: $color-text;
          }
        }
        .btn-group-radio-vertical {
          .pr-btn {
            border-color: $color-lighter-gray;
            &:hover {
              background-color: $color-lighter-gray;
              color: $color-text;
            }
            &.active {
              background-color: $color-lighter-gray;
              color: $color-text;
            }
            &:not(.active){
              &::before {
                content: '';
                border-radius: 50%;
                padding: 6px;
                border: 1px solid $color-text;
              }
            }
          }
          &.btn-toggle {
            & > :last-child {
              border-bottom-width: 1px;
            }
          }
        }
      }
      .pr-qa-display-search {
        @media only(max-width: $medium-up) {
          padding: 0;
        }
        padding: 0 10%;
        background-color: $white;
        form {
          label {
            display: none;
          }
          .pr-qa-display-searchbar {
            input {
              width: 235px !important;
              border: 1px solid $color-lighter-gray;
              border-right: 1px solid $color-lighter-gray;
              float: right;
              top: 50px;
              font-family: $font--text;
              &:focus {
                border: 1px solid $color-lighter-gray;
                border-right: 1px solid $color-lighter-gray;
              }
              @media only(max-width: $med-small-up) {
                top: 60px;
              }
            }
            .pr-qa-search-btn {
              display: none;
            }
          }
        }
        .pr-qa-display-clear-search-button {
          position: absolute;
          width: 150px;
          right: 10%;
          font-family: $font--text;
          top: 90px;
          color: $color-text;
          @media only(max-width: $medium-up) {
            right: 0;
          }
          &:hover {
            color: $color-text;
          }
        }
      }
      .pr-qa-sort-group {
        bottom: 140px;
        padding: 0 10%;
        &:after {
          display: none;
        }
        @media only(min-width: $med-small-up) {
          bottom: 90px;
        }
        @media only(max-width: $medium-up) {
          padding: 0;
        }
        .pr-qa-sort {
          padding: 5px 55px 5px 5px;
          &:after {
            display: none;
          }
        }
        label {
          display: inline-block;
          font-family: $font--text;
        }
      }
      .pr-qa-display-question {
        order: 3;
        padding: 10px;
        .pr-helpful-voting {
          .pr-helpful-active {
            background: $color-cyan-green !important;
            span {
              svg {
                path {
                  fill: $white;
                }
              }
            }
            .pr-helpful-count {
              color: $white;
            }
            &:hover {
              color: $white;
            }
          }
          .pr-thumbs-icon {
            svg {
              path {
                fill: $color-black;
              }
              &:hover {
                fill: $white;
              }
            }
          }
          .pr-helpful-btn {
            background-color: $color-white;
            min-width: auto;
            border: none;
            &:hover {
              background: $color-cyan-green;
              outline: none;
              .pr-thumbs-icon {
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
              .pr-helpful-count {
                color: $color-white;
              }
            }
          }
        }
        .pr-btn-answer {
          border: 1px solid $color-lighter-gray;
          padding: 12px 50px;
          color: $color-text;
          .pr-cross-icon {
            display: none;
          }
          .pr-qa-display-text {
            color: inherit;
            text-shadow: none;
            background-image: none;
          }
        }
        .pr-qa-display-answer {
          .pr-qa-display-answer-icon {
            display: none;
          }
          .pr-qa-display-answer-by-container {
            &.pr-qa-display-answer-by-container-expert {
              border-left: 1px solid $color-dark-gray;
              padding-left: 15px;
            }
          }
        }
        .pr-subscript {
          font-size: 16px;
          color: $color-black;
        }
        .pr-qa-display-item-icon {
          background-color: $color-dark-gray;
        }
        .pr-qa-display-item-details {
          margin-top: 15px;
          @media only(min-width: $medium-up) {
            margin-top: 0px;
          }
        }
        time {
          margin-left: 0;
          display: block;
          font-size: 16px;
          color: $color-dark-gray;
        }
      }
      .pr-qa-display-item-text {
        color: $color-dark-gray;
        @media only(max-width: $medium-up) {
          padding-top: 6px;
          padding-left: 32px;
        }
      }
      .pr-qa-display-answer-details {
        span {
          color: $color-dark-gray;
        }
      }
      .pr-qa-display-btn-text {
        color: $color-dark-gray;
        span {
          color: $color-dark-gray;
        }
      }
      .pr-caret-icon__line {
        stroke: $color-dark-gray;
      }
      .pr-qa-display-show-more-questions {
        background-color: $white;
        border: 1px solid $color-light-gray;
        &:hover {
          background-color: $white;
          border: 1px solid $color-light-gray;
        }
      }
      .pr-qa-display-questionlist {
        border-bottom: 1px solid $color-lighter-gray;
        @media only(max-width: $medium-up) {
          border-left: none;
          display: flex;
          flex-direction: column;
          padding-left: 0;
          margin: 0;
          .pr-qa-display-item {
            order: 2;
            margin-bottom: 20px;
            .pr-qa-display-item-icon {
              position: static;
              float: left;
            }
          }
          .pr-qa-display-item-details {
            order: 1;
          }
          .pr-qa-display-answer-link-container {
            order: 3;
            margin-left: 32px;
          }
          .pr-qa-display-add-answer {
            order: 4;
            .pr-answer-form {
              fieldset {
                padding-left: 32px;
              }
              .pr-header-required {
                .pr-required-indicator {
                  color: $color-text;
                }
              }
            }
          }
          .pr-qa-display-answer {
            order: 5;
            margin-left: 32px;
          }
        }
        .pr-answer-form {
          fieldset {
            font-family: $font--text;
            .pr-submit {
              .pr-accessible-btn {
                background-color: $color-cyan-green;
                border-color: none;
                border-bottom: 0;
                color: $black;
                -webkit-appearance: none;
              }
            }
            &:hover {
              color: $white;
            }
          }
        }
      }
      .pr-qa-sort-headline {
        font-family: $font--text;
        font-size: 16px;
        padding-top: 15px;
      }
      .pr-qa-display-sort {
        padding: 60px 0 0 0;
      }
      .pr-qa-display-search-no-results {
        margin-top: 80px;
      }
      .pr-qa-display-answer-by-container {
        &.pr-qa-display-answer-by-container-unverified {
          .pr-qa-display-answer-details {
            padding-left: 20px;
            border-left: 1px solid $color-dark-gray;
          }
        }
      }
      .pr-qa-display-thankyou-headline {
        @include breakpoint($med-small-down) {
          padding-bottom: 20px;
        }
      }
    }
    .pr-question-form {
      .pr-header-required {
        display: none;
      }
      .pr-waq-legal-text {
        span {
          color: $color-text;
          line-height: 17px;
        }
        a {
          color: $color-text;
          text-decoration: underline;
        }
      }
    }
  }
}

#pr-question-form-link {
  @media only(min-width: $landscape-up) {
    display: inherit;
    margin: 15px 0px 10px 0;
    padding: 10px 70px;
    float: right;
    width: auto !important;
  }
  background-color: $color-white;
  outline: none;
  border: 1px solid $color-lighter-gray;
  color: $color-text;
  z-index: 1;
  display: flex;
  height: 45px;
  margin: 0 auto;
  float: none;
  width: 100% !important;
  padding: 12px 30px;
  .pr-qa-display-link {
    width: 100%;
    color: $color-text;
    padding: 0 20px;
    &:hover {
      background-image: none;
      text-shadow: none;
    }
  }
}

.product-full {
  &__select-wrapper {
    margin-top: 80px;
    @media only(min-width: $landscape-up) {
      margin-top: 25px;
    }
  }
  &__price-wrapper {
    margin-top: 40px;
    @media only(min-width: $landscape-up) {
      margin-top: 0;
    }
  }
}

.pr-header-table {
  .table-cell {
    a {
      display: none;
    }
  }
}

.search-wrapper {
  &__results {
    .p-w-r {
      text-align: left;
      @include breakpoint($landscape-up) {
        text-align: center;
      }
    }
  }
}

.product-grid-wrapper {
  .pr-snippet {
    .pr-category-snippet__total {
      display: none;
    }
  }
}

.add-your-answer-button--hide {
  .pr-qa-display-answer-link-container {
    display: none;
  }
}
