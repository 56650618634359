.offer-email-signup {
  padding: 35px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  background: $color-light-green;
  @include breakpoint($landscape-up) {
    width: 70%;
  }
  &__header {
    font-size: 24px;
    margin-bottom: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
  }
  &__legal {
    font-size: 14px;
  }
  input[type='text'] {
    font-size: 12px;
    width: 60%;
    height: 60px;
    color: $color-dark-gray;
    @include breakpoint($landscape-up) {
      width: 45%;
    }
    &.offer-birthday-month,
    &.offer-birthday-day {
      width: 100px;
    }
  }
  input[type='submit'] {
    height: 60px;
    background: $color-black;
    color: $color-white;
    padding: 0 30px;
    &:hover {
      color: $color-black;
      background: $color-white;
    }
  }
  &__sign-in {
    text-align: center;
  }
  &__collection-field {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__anchor {
    display: block;
    height: 390px;
    margin-top: -390px;
    visibility: hidden;
  }
}
