.video-block {
  position: relative;
  padding-top: 40px;
  h1,
  h2 {
    text-align: center;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
  }
  &__hide {
    position: relative;
  }
  &__landing {
    position: relative;
  }
  &__play {
    background-image: url('/media/export/cms/play.png');
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 40px;
    height: 71px;
    width: 71px;
    z-index: 100;
    &:before {
      content: none;
    }
  }
  &__reveal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  video {
    display: block;
    width: 100%;
    max-height: 100%;
  }
  &__container {
    &-header {
      text-align: center;
      margin: 0 0 20px 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 20px;
      font-weight: bold;
      font-family: $main-font;
    }
    &-subtext {
      text-align: center;
    }
  }
  .retinol-landing__video & {
    padding-top: 0;
  }
  .hero-large & {
    padding-top: 0;
  }
}

.video-image {
  width: 100%;
  display: inline-block;
  @include breakpoint($landscape-up) {
    width: 80%;
    margin-left: 16%;
  }
  .retinol-landing__video & {
    @include breakpoint($landscape-up) {
      margin-left: 10%;
    }
  }
  &__item {
    width: 100%;
    margin-top: 5%;
    text-align: center;
    @include breakpoint($landscape-up) {
      width: 29%;
      float: left;
    }
    .retinol-landing__video & {
      @include breakpoint($landscape-up) {
        width: 33%;
      }
    }
  }
  &__text {
    text-align: center;
    width: 90%;
    margin: 30px 5% 0 5%;
    @include breakpoint($landscape-up) {
      margin: 30px 0 0 0;
      width: 100%;
    }
  }
  .retinol-landing__video & {
    border: 5px solid $color-light-sea-green;
    border-top: none;
    border-bottom: none;
  }
}

.content-block {
  .learn_more-button {
    text-align: center;
    margin-top: 20px;
    .retinol-landing__video & {
      @include breakpoint($landscape-up) {
        padding: 24px 33px 0 33px;
        display: block;
        border: 5px solid $color-light-sea-green;
        width: 80%;
        margin: -10px auto 0 auto;
        border-top: none;
        border-bottom: none;
      }
    }
    a {
      font-weight: bold;
    }
  }
}

#colorbox.overlay--content-block-video {
  opacity: 0;
  transition: opacity 0.4s;
  &.active {
    opacity: 1;
  }
  #cboxLoadedContent {
    padding: 0;
    margin: auto;
  }
  video {
    display: block;
    width: 100%;
    max-height: 100%;
  }
  &.overlay--content-block-video-product {
    #cboxClose {
      position: fixed;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      color: $color-dark-black;
      background-color: $color-white;
      &:before {
        font-weight: bold;
        font-size: 20px;
      }
    }
    #cboxLoadedContent {
      padding: 40px;
    }
  }
}
