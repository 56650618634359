///////////////////////////////
/// HERO LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////
.hero-large {
  cursor: pointer;
  height: auto;
  position: relative;
  width: 100%;
  &.hero-large--color-light {
    background-color: $color-black;
  }
  &__inner {
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      position: absolute;
      z-index: 3;
      top: 1em;
      bottom: auto;
      // content widths
      &.hero-large__inner-width--20 {
        width: 20%;
      }
      &.hero-large__inner-width--30 {
        width: 30%;
      }
      &.hero-large__inner-width--40 {
        width: 40%;
      }
      &.hero-large__inner-width--50 {
        width: 50%;
      }
      &.hero-large__inner-width--60 {
        width: 60%;
      }
      &.hero-large__inner-width--70 {
        width: 70%;
      }
      &.hero-large__inner-width--80 {
        width: 80%;
      }
      &.hero-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--center {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &-hpos--left {
        left: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          left: 50%;
        }
        &.hero-large__inner-margin--40 {
          left: 40%;
        }
        &.hero-large__inner-margin--30 {
          left: 30%;
        }
        &.hero-large__inner-margin--20 {
          left: 20%;
        }
        &.hero-large__inner-margin--10 {
          left: 10%;
        }
      }
      &-hpos--right {
        right: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          right: 50%;
        }
        &.hero-large__inner-margin--40 {
          right: 40%;
        }
        &.hero-large__inner-margin--30 {
          right: 30%;
        }
        &.hero-large__inner-margin--20 {
          right: 20%;
        }
        &.hero-large__inner-margin--10 {
          right: 10%;
        }
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .hero-large__content {
          @include centerer();
        }
      }
      &-vpos--top {
        top: 1em;
      }
      &-vpos--bottom {
        position: absolute;
        bottom: 1em;
        top: auto;
      }
    }
  }
  &__content {
    padding: 1em;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__text {
    margin-bottom: 10px;
    .hero-large--color-light & {
      color: $color-white;
    }
    p {
      margin: 0;
    }
  }
  &__cta {
  }
  &__image,
  &__image img {
    width: 100%;
  }
  &__image img {
    float: left;
  }
}
