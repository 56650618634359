.store-locator {
  &__content {
    position: relative;
    width: 100%;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  &__breadcrumb {
    display: none;
    @include breakpoint($landscape-up) {
      margin: 2% 0;
      text-transform: uppercase;
      display: block;
      font-size: 13px;
      letter-spacing: 1px;
    }
    a {
      color: $color-light-grey-breadcrumb;
      padding: 0 5px;
    }
    &--title-main {
      font-weight: bold;
    }
  }
  &__title {
    font-weight: normal;
    padding: 0 5px;
  }
  a {
    text-decoration: none;
    &.marker {
      color: $color-black;
      letter-spacing: 2px;
    }
  }
  h1.doorname {
    margin-bottom: 10px;
  }
  .hidden,
  .inactive,
  .hide,
  &__sections {
    display: none;
  }
  fieldset {
    display: inline;
    width: 100%;
    text-align: right;
    padding: 10px;
    @include breakpoint($landscape-up) {
      margin-left: 10px;
      padding: 0;
    }
    @include breakpoint($large-up) {
      margin-left: 0px;
    }
  }
  &__header {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    margin: 20px 0;
    letter-spacing: 2px;
    @include breakpoint($landscape-up) {
      font-size: 2em;
    }
  }
  &__info {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      margin: 10px 0 10px 0;
      padding: 0 5px;
      font-size: 12px;
    }
  }
  .results-count {
    text-align: center;
    font-size: 14px;
  }
  &__info-strong {
    font-weight: bold;
  }
  &__button {
    text-align: center;
    display: block;
    float: left;
    width: 49%;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    height: 35px;
    color: $color-black;
    border: 1px solid $color-black;
    margin-right: 1%;
    &:last-child {
      margin-left: 1%;
      margin-right: 0;
    }
    &.active {
      background: $color-black;
      color: $color-white;
    }
    .icon {
      margin-top: 2px;
      font-size: 28px;
      margin-left: 14px;
      float: left;
    }
  }
  &-section--list_item-section {
    // not exist
    display: table;
    margin-top: 14px;
    font-size: 14px;
    width: 100%;
    padding: 15px 0;
    background: $color-white;
    border: 1px solid $color-black;
    h4 {
      text-transform: uppercase;
    }
  }
  &-list__hours {
    border-right: 1px solid $color-black;
  }
  &-list__hours,
  &-list__event {
    display: table-cell;
    padding: 0 15px;
    width: 50%;
  }
  &__tooltip {
    .store-locator__tooltip-name {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      &.popup {
        color: $color-black;
        text-transform: capitalize;
        font-weight: bold;
      }
      .distance {
        float: right;
      }
    }
    .store-locator {
      &__tooltip-phone,
      &__tooltip-oab {
        color: $color-black;
        text-decoration: underline;
        float: left;
        margin: 10px 20px 0 0;
        padding-right: 20px;
        border-right: 1px solid $color-black;
        font-weight: bold;
      }
      &__tooltip-directions {
        text-decoration: underline;
        font-weight: bold;
        float: left;
        margin-top: 10px;
        padding-right: 20px;
      }
      &__tooltip-oab {
        border-right: 0px;
        padding-left: 20px;
        border-left: 1px solid $color-black;
      }
    }
    .column {
      width: 90%;
      float: left;
    }
    &.info_window,
    &.info_window:last-child {
      width: 100%;
      margin: 0;
    }
    &-hours {
      &--arrow {
        @include icon('caret--down', after);
        font-weight: bold;
        &::after {
          padding: 15px;
        }
      }
      &--parsed {
        display: none;
      }
      &.active {
        .store-locator {
          &__tooltip-hours {
            &--arrow {
              @include icon('caret--up', after);
            }
            &--parsed {
              display: block;
            }
          }
        }
      }
    }
  }
  &__map-canvas-container,
  &__results-container {
    width: 100%;
    @include breakpoint($landscape-up) {
      float: left;
      margin-left: 10px;
    }
    @include breakpoint($large-up) {
      float: left;
      margin-left: 0px;
    }
  }
  &__map-canvas-container {
    @include breakpoint($landscape-up) {
      width: 54%;
      float: right;
      position: relative;
      right: 0;
    }
  }
  &__results-container {
    @include breakpoint($landscape-up) {
      width: 42%;
    }
    .store-locator__directions {
      display: none;
    }
    .store-locator__stores {
      @include breakpoint($landscape-up) {
        background-color: $color-white-smoke;
        padding: 10px;
        border-bottom: 15px solid $color-lighter-gray;
      }
    }
    table {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 93%;
        margin: 0px 0px 0px 20px;
      }
    }
    &.active {
      #store-locator__search {
        @include breakpoint($landscape-up) {
          float: left;
          margin: 0 4px 0 0px;
          width: 41%;
        }
        @include breakpoint($large-up) {
          float: left;
          margin: 0 15px 0 0px;
          width: 49%;
        }
      }
      .search-submit {
        @include breakpoint($landscape-up) {
          margin: 20px 0px;
          width: 86%;
        }
        @include breakpoint($large-up) {
          margin: 20px 0px;
          width: 88%;
        }
      }
      .store-locator__locate {
        margin: 0px 5px;
        background: $color-dark-green;
        display: inline-block;
        @include breakpoint($landscape-up) {
          display: inline-block;
          padding: 7px 10px;
        }
        @include breakpoint($medium-down) {
          display: inline-block;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }
    .distance_container {
      margin: 20px 0;
      float: left;
      width: 100%;
      @include breakpoint($landscape-up) {
        margin: 0;
        width: 45%;
      }
      @include breakpoint($large-up) {
        width: 48%;
      }
      label {
        text-transform: capitalize;
        font-size: 14px;
      }
      select {
        width: 60%;
        margin-left: 10px;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
    input.search[type='text'] {
      @include breakpoint($landscape-up) {
        width: 38%;
        float: left;
        margin-right: 15px;
      }
      @include breakpoint($large-up) {
        width: 49%;
      }
    }
  }
  &__map-canvas-container,
  &__map-canvas {
    min-height: 300px;
    margin-bottom: 20px;
    @include breakpoint($landscape-up) {
      min-height: 695px;
      top: 0px;
      margin-right: 10px;
    }
    @include breakpoint($large-up) {
      margin-right: 0px;
    }
  }
  #map-canvas {
    height: 100%;
    margin: 0;
    padding: 0;
    img {
      max-width: none;
    }
  }
  input[type='text'],
  select {
    &:focus {
      border-color: $color-dark-green;
    }
  }
  &__result-pin {
    height: 30px;
    width: 20.4px;
    padding-top: 15px;
    &-image {
      height: 30px;
      width: 20.4px;
    }
  }
  &__either-or {
    display: none;
  }
  &__locate {
    display: inline-block;
    background: $color-dark-green;
    padding: 7px 10px;
    @include breakpoint($landscape-up) {
      position: relative;
      top: 20px;
      left: 6px;
      float: left;
    }
    @include breakpoint($large-up) {
      left: 17px;
    }
    span.text {
      display: none;
    }
    .icon__location-image {
      background: url('/media/images/locator/location-icon.svg');
      font-size: 24px;
      line-height: 24px;
      vertical-align: middle;
      min-height: 24px;
      min-width: 24px;
    }
  }
  &__hero {
    padding: 5px;
    @include breakpoint($landscape-up) {
      padding: 0;
      width: 42%;
      float: left;
      margin: 2% 0 0;
    }
    text-align: center;
    margin: 0px;
    .locator_error_messages {
      li {
        color: $color-error;
      }
    }
    select.country {
      display: inline;
    }
    img {
      margin: 0;
      max-width: none;
      height: auto;
      vertical-align: bottom;
    }
    &-image {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
        position: relative;
      }
      img {
        height: 150px;
        width: 100%;
      }
    }
    &-inner {
      position: absolute;
      top: 14%;
      left: 5%;
      width: 90%;
      text-align: center;
      padding: 20px 0;
      text-transform: uppercase;
      background: $color-light-green-transparent;
      &--main,
      &--sub {
        font-weight: normal;
        padding-bottom: 8px;
        a {
          text-decoration: underline;
        }
        .banner-store {
          font-weight: bold;
        }
      }
      h2 {
        font-size: 20px;
      }
    }
    a.selectBox {
      text-align: left;
      display: inline-block;
      width: 220px;
      float: left;
      height: 32px;
      line-height: 32px;
      margin: 0 10px 0 5px;
      .selectBox-arrow {
        line-height: 2em;
      }
    }
    input.search[type='text'] {
      width: 81%;
      margin: 0 10px 0 0;
      float: left;
      letter-spacing: 1px;
      @include breakpoint($medium-up) {
        width: 92%;
      }
      @include breakpoint($landscape-up) {
        width: 41%;
        font-size: 12px;
        margin: 0px 4px 0 0px;
      }
      @include breakpoint($large-up) {
        margin: 0px 15px 0 0px;
        width: 49%;
        font-size: 13px;
      }
      &::-webkit-input-placeholder {
        color: $color-light-grey;
        opacity: 0.8;
      }
      &::-moz-placeholder {
        color: $color-light-grey;
        opacity: 0.8;
      }
      &::-ms-input-placeholder {
        color: $color-light-grey;
        opacity: 0.8;
      }
    }
    #store-search-controls {
      .required_field {
        border: 1px solid $color-error;
      }
    }
    .distance_container {
      margin: 20px 0;
      float: left;
      width: 100%;
      @include breakpoint($landscape-up) {
        margin: 0;
        width: 45%;
      }
      @include breakpoint($large-up) {
        width: 48%;
      }
      label {
        text-transform: capitalize;
        font-size: 14px;
      }
      select {
        width: 65%;
        margin-left: 10px;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
  }
  .search-submit {
    float: left;
    margin: 10px 0px 0;
    width: 100%;
    @include breakpoint($landscape-up) {
      display: block;
      background: $color-light-green;
      height: 40px;
      line-height: 32px;
      font-weight: normal;
      margin: 20px 2px 20px 0;
      width: 88%;
      float: left;
      .icon-search {
        display: none;
      }
      &:hover {
        border-color: $color-dark-green;
      }
      &__text {
        display: inline-block;
      }
    }
  }
  &__search {
    display: inline-block;
    form {
      padding: 0;
      width: auto;
    }
  }
  .search-form {
    background: transparent;
  }
  &-section--list_item {
    padding: 0;
    border-bottom: 1px solid $color-lighter-gray;
    background-color: $color-white;
    @include breakpoint($landscape-up) {
      margin: 10px 0px;
      border: none;
    }
    .store-locator__button-container,
    .store-locator-section,
    .column--hours,
    &-location {
      display: block;
    }
    .store-locator__tooltip {
      padding: 15px;
      font-size: 14px;
    }
    &.active {
      background: $color-light-green;
    }
    &:hover {
      cursor: pointer;
      background: $color-light-green;
    }
    .column {
      margin: 0;
      @include breakpoint($medium-up) {
        width: 60%;
      }
      @include breakpoint($landscape-up) {
        width: 92%;
      }
    }
    .store-locator__button {
      margin-top: 13px;
      &.active {
        border: none;
        background: transparent;
        color: $color-black;
      }
    }
  }
  &__stores {
    height: auto;
    @include breakpoint($landscape-up) {
      height: 555px;
      padding: 10px 0px;
      overflow-y: scroll;
    }
  }
  #store_results {
    @include breakpoint($landscape-up) {
      max-height: 95%;
    }
  }
  &__event {
    display: none;
    background: $color-black;
    color: $color-white;
    padding: 10px 0 0;
    .icon-close {
      display: none;
      color: $color-white;
    }
    &-list {
      display: none;
      color: $color-black;
      background: $color-white;
      border: 1px solid $color-black;
      h5 {
        color: $color-black;
      }
    }
  }
  &__section-list {
    .store-locator__tooltip {
      .store-locator__event {
        display: none;
      }
    }
    .store-locator__event {
      font-size: 14px;
      &-label {
        padding: 10px;
      }
      &-list__item {
        padding: 10px;
        h5 {
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      .icon-close {
        float: right;
      }
    }
  }
  .store-locator__directions {
    font-size: 12px;
    background-color: $color-white;
    &-location {
      padding: 15px;
      line-height: 20px;
      background: $color-white;
      border-color: $color-black;
      border-width: 1px 0;
      .store-locator__directions-destination {
        line-height: 40px;
        vertical-align: middle;
        float: left;
        height: 40px;
        width: 40px;
        text-align: center;
        background: $color-light-green;
        color: $color-white;
        margin-right: 10px;
      }
      .store-locator__directions-eta {
        color: $color-dark-green;
      }
      .directions-form {
        &__errors {
          margin-bottom: 1em;
          color: $color-dark-green;
        }
        &__input-wrapper-a {
          margin-bottom: 11px;
        }
        &__input-wrapper-b {
          margin-bottom: 22px;
        }
        &__submit {
          margin: 0 0 22px 55px;
        }
        &__input-wrapper {
          .store-locator__directions-destination {
            height: 32px;
            width: 32px;
            line-height: 32px;
            background: $color-black;
          }
          .directions-form__input {
            width: 215px;
          }
        }
        &__transportation-modes {
          width: 100%;
          text-align: justify;
          .directions-form__transportation-mode-link {
            vertical-align: top;
            display: inline-block;
            zoom: 1;
            color: $color-black;
            &.active {
              color: $color-dark-green;
            }
          }
          .stretch {
            width: 100%;
            display: inline-block;
            font-size: 0;
            line-height: 0;
          }
        }
        &__results {
          background-color: $color-white;
        }
      }
      &-start,
      &-end {
        background-color: $color-light-green;
        border-color: $color-black;
        border-style: solid;
        border-width: 1px 0;
      }
      table.adp-placemark {
        display: none;
      }
    }
  }
  .adp-directions tr {
    .adp-substep:nth-child(1),
    .adp-substep:nth-child(2) {
      width: 10%;
    }
    .adp-substep:nth-child(3) {
      width: 65%;
    }
  }
  &__controls {
    .store-locator__filter-controls {
      margin: 15px 5px;
      @include breakpoint($landscape-up) {
        display: inline-block;
        margin: 0px 5px;
      }
      select {
        width: 65%;
        font-size: 14px;
        text-transform: uppercase;
        @include breakpoint($medium-up) {
          width: 85%;
        }
        @include breakpoint($landscape-up) {
          width: 54%;
          padding-right: 25px;
        }
        @include breakpoint($large-up) {
          padding-right: 40px;
        }
      }
      label {
        padding-right: 0px;
      }
      &.mobile-hidden {
        @include breakpoint($med-small-down) {
          display: none;
        }
      }
      &.pc-hidden {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
    }
  }
  &__body {
    @include breakpoint($landscape-up) {
      margin: 25px 0px;
    }
  }
  &__search-controls {
    input.search[type='text'],
    select.city {
      @include breakpoint($landscape-up) {
        margin: 0 15px 0 0;
        width: 48%;
        float: left;
      }
    }
  }
}
