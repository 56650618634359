.slick {
  &-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  &-list {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  &-track,
  &-list {
    @include transform(translate3d(0, 0, 0));
  }
  &-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  &-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    outline: none;
    [dir='rtl'] & {
      float: right;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  &-prev,
  &-next {
    @include appearance(none);
    //@include transition(opacity 300ms ease);
    position: absolute;
    z-index: 100;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    //color: transparent;
    top: 50%;
    padding: 0;
    margin-top: -25px;
    border: none;
    outline: none;
    opacity: 0.3;
    color: $color-black;
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
    border-radius: 0;
    &:hover {
      opacity: 0.5;
      background: transparent;
      .basic-carousel-formatter__wrapper & {
        opacity: 1;
      }
    }
    &:focus {
      outline: none;
    }
    &:before {
      font-size: 35px;
      line-height: 1;
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
      &:hover {
        opacity: 0.4;
        background: transparent;
      }
    }
  }
  &-prev {
    @include icon('caret--left', $text-replace: true);
    position: absolute;
    left: 60px;
    .carousel-controls--limited-width & {
      left: -60px;
    }
    &:before {
      position: static;
      height: auto;
    }
  }
  &-next {
    @include icon('caret--right', $text-replace: true);
    position: absolute;
    right: 70px;
    text-align: right;
    .carousel-controls--limited-width & {
      right: -50px;
    }
    &:before {
      position: static;
      height: auto;
    }
  }
}

// Default Variables

$slick-dot-color: $color-gray;
$slick-dot-color-active: $color-black;
$slick-dot-size: 10px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.75;
$slick-opacity-not-active: 0.25;

// Dots

.slick-dots {
  text-align: center;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: $slick-dot-size;
    width: $slick-dot-size;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size;
      width: $slick-dot-size;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        width: 9px;
        height: 9px;
        background: $color-gray;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: $slick-dot-size;
        height: $slick-dot-size;
        //font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: $slick-dot-size;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    .basic-carousel-formatter__wrapper & {
      margin: 0 8px;
    }
    &.slick-active button:before {
      background: $color-black;
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
      .basic-carousel-formatter__wrapper & {
        background: $color-green-v2;
      }
    }
  }
}
