.link-based-offer {
  @include clearfix;
  padding: 20px;
  &__image {
    float: left;
  }
  &__description {
    float: left;
    margin-left: 20px;
  }
  &__header {
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__message {
  }
}

.offer-detail-section {
  .product-grid {
    &--carousel {
      .slick-slide {
        min-height: 450px;
        @include breakpoint($landscape-up) {
          min-height: 695px;
        }
      }
    }
  }
  .content-block-large {
    margin-bottom: 0px;
    @include breakpoint($landscape-up) {
      margin-bottom: 70px;
    }
    &__inner {
      margin-bottom: 0px;
      @incluse breakpoint($landscape-up) {
        margin-bottom: 15px;
      }
    }
  }
}
