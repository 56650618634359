.offer-three-up {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;
  .basic-grid-formatter {
    padding: 20px 0;
    @include content-container;
    @include breakpoint($landscape-up) {
      background: $color-light-green-transparent;
      padding: 0;
      margin: 0 auto 5%;
    }
  }
  .basic-grid {
    padding-right: 0px;
  }
  .offer-3up {
    margin: 30px 0 0 0;
    @include breakpoint($landscape-up) {
      height: 430px;
      position: relative;
      margin: 10px 20px;
    }
    &__header {
      margin: 0px auto 15px auto;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      @include breakpoint($landscape-up) {
        margin: 20px auto 15px auto;
        font-size: 18px;
      }
    }
    &__description {
      margin: 0px 10px;
      text-align: center;
      padding-bottom: 30px;
      @include breakpoint($landscape-up) {
        margin: 0px;
        border-bottom: 0px;
        padding-bottom: 0px;
      }
    }
    &__bottom {
      @include breakpoint($landscape-up) {
        border: none;
      }
    }
    &__link {
      text-decoration: none;
    }
    &__message {
      font-size: 14px;
      margin: 0 auto;
      width: 80%;
      padding-bottom: 20px;
    }
    &__button {
      position: relative;
      color: $color-black;
      background: $color-dark-green;
      @include breakpoint($landscape-up) {
        position: absolute;
        bottom: 0;
        background: $color-white;
        #{$ldirection}: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.offer-detail-section {
  .content-block {
    &-formatter__headline {
      padding: 10px;
      h2 {
        font-size: 22px;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .product-grid__content {
    .product__image-link {
      width: 70%;
      margin: 10% auto;
      @include breakpoint($landscape-up) {
        width: 50%;
        margin: 0;
      }
    }
    .product-brief {
      &__description-container {
        width: 100%;
        text-align: center;
        min-height: 290px;
        position: relative;
        @include breakpoint($landscape-up) {
          width: 70%;
          min-height: 255px;
        }
      }
      &__name {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
      &__price {
        float: none;
      }
    }
    .button {
      @include breakpoint($landscape-up) {
        margin: 0 66px;
      }
    }
  }
}
