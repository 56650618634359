.country-chooser {
  position: relative;
  float: none;
  text-transform: none;
  width: 100%;
  padding: 0;
  @include breakpoint($landscape-up) {
    float: left;
    text-transform: uppercase;
    width: 25%;
    padding: 5px 5%;
    z-index: 1;
  }
  .country_chooser__header {
    margin-bottom: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }
  .country-chooser__section {
    .field-menu {
      position: relative;
    }
    .country-chooser__selector {
      text-transform: none;
      width: 100%;
      display: block;
      border: 1px solid $color-lighter-grey;
      padding: 7px 12px 7px 10px;
      text-align: left;
      &:focus {
        outline: 0;
      }
    }
    a.country-chooser__selector {
      color: $color-gray;
      text-decoration: none;
      text-transform: uppercase;
    }
    .menu {
      display: none;
      position: absolute;
      left: 0;
      background: $color-white;
      border: 1px solid $color-lighter-grey;
      border-bottom: 0;
      width: 100%;
      max-height: 276px; //12 open
      overflow: scroll;
      li {
        display: block;
        text-align: left;
        width: 100%;
        a {
          display: block;
          border-bottom: 1px solid $color-lighter-gray;
          line-height: 22px;
          text-decoration: none;
          padding: 2% 12px;
          text-transform: uppercase;
        }
      }
    }
  }
  &__selector {
    background-image: url(/media/export/cms/arrow_down.png);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    img {
      margin-right: 5px;
      padding-bottom: 4px;
    }
  }
}
