.sign-in-component__birthday-program {
  width: 100%;
  overflow: hidden;
  margin: 10px 0;
  text-align: left;
}

.day-of-birth__collection-field {
  padding: 10px 0;
  select {
    width: 30%;
    float: left;
    margin-right: 3%;
    font-size: 12px;
    text-transform: uppercase;
    color: $color-light-gray;
  }
}

.login__container {
  padding: 22px 28px 20px 24px;
  text-align: left;
  input,
  submit {
    width: 100%;
    margin-bottom: 15px;
    font-size: 12px;
    &::placeholder {
      text-transform: uppercase;
    }
  }
  .login__forgot-password {
    margin-bottom: 15px;
  }
}

.login {
  text-transform: uppercase;
  &__forgot-password {
    margin-bottom: 10px;
  }
  &__registration-alt {
    padding: 10px 0;
  }
  &__register {
    margin: 0 27px;
    width: 84%;
  }
  &__no-account {
    padding: 0 27px;
    text-align: left;
  }
  &__privacy_copy {
    margin: 15px 0;
  }
  .mailing-list__wrapper {
    @include swap_direction(padding, 0 40px 20px 50px);
    text-align: $ldirection;
    input[type='checkbox'] {
      left: 25px;
    }
  }
}

.fb-login-button {
  padding-bottom: 10px;
}

.account-links {
  padding: 20px 15px;
  &__container {
    li {
      width: 100%;
      text-align: left;
      padding-bottom: 10px;
      &:first-child {
        font-weight: bold;
      }
    }
  }
}
