.STYLEGUIDE__subsection {
  margin: 0 0 10px;
  .carousel-controls {
    display: flex;
  }
  .slick-prev,
  .slick-next {
    position: relative;
    margin: 10px;
    left: 0;
  }
}

.STYLEGUIDE__style-description {
  font-family: Monospace;
}

ul.STYLEGUIDE__style-description {
  background: $color-lighter-grey;
  padding: 10px;
  code {
    color: $color-light-gray;
  }
}

h2.STYLEGUIDE__subsection__title {
  background: black;
  color: white;
  padding: 10px;
  font-size: 32px;
  margin: 0 0 10px;
  text-align: center;
}

h3.STYLEGUIDE__subsection__title {
  font-size: 28px;
  text-align: center;
}

.STYLEGUIDE__color {
  float: left;
  word-wrap: break-word;
  width: 20%;
  min-height: 240px;
  padding: 20px;
  margin: 0 20px 0 0;
  span {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
    &.STYLEGUIDE__usage {
      opacity: 0.5;
    }
  }
  &.STYLEGUIDE__color--light-green {
    background-color: $color-light-green;
  }
  &.STYLEGUIDE__color--dark-green {
    background-color: $color-dark-green;
  }
  &.STYLEGUIDE__color--lighter-grey {
    background-color: $color-lighter-grey;
  }
  &.STYLEGUIDE__color--black {
    background-color: $color-black;
  }
  &.STYLEGUIDE__color--dark {
    color: $color-white;
  }
  &.STYLEGUIDE__color--light {
    color: $color-black;
  }
}
